import Flatpickr from 'stimulus-flatpickr'
import { Flatpickr_Locales } from '../packs/locales'
// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static values = { date: String, locale: String }

  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      dateformat: "U",
      inline: true,
      locale: this.locale,
      position: "auto center"
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    this.dateValue = selectedDates[0]
    //console.log('the callback returns the selected dates', selectedDates)
    //console.log('but returns it also as a string', dateStr)
    //console.log('and the flatpickr instance', instance)
  }

  get locale() {
    if (this.hasLocaleValue) {
      return Flatpickr_Locales[this.localeValue]
    } else {
      return ""
    }
  }
}
