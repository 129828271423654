import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    data: Object,
    emptyImage: String
  }

  static targets = [ 'mostUpsoldProduct', 'mostUpsoldProductImg', 'mostUpsoldProductTitle', 'mostUpsoldProductVariantTitle', 'revenue', 'pcOffer', 'quantity'] 

  connect(){
    this.updateData()
  }

  updateData(){
    if(this.dataValue.products.length > 0) {
      this.updateMostUpsold(this.dataValue.most_upsold_product)
    }
    else {
      this.hideMostUpsoldProduct()
    }
    this.updateDataTable(this.dataValue.products)
  }

  hideMostUpsoldProduct(){
    this.mostUpsoldProductImgTarget.src = this.emptyImageValue
    this.mostUpsoldProductTitleTarget.innerHTML = ''
    this.mostUpsoldProductVariantTitleTarget.innerHTML = ''
    this.revenueTarget.innerHTML = '0.0'
    this.pcOfferTarget.innerHTML = '0.0'
    this.quantityTarget.innerHTML = '0'
  }

  updateMostUpsold(data){
    this.mostUpsoldProductImgTarget.src = data.featured_image_src
    this.mostUpsoldProductTitleTarget.innerHTML = data.product_title
    this.mostUpsoldProductVariantTitleTarget.innerHTML = data.variant_title
    this.revenueTarget.innerHTML = data.revenue
    this.pcOfferTarget.innerHTML = data.pc_upsell_revenue
    this.quantityTarget.innerHTML = data.quantity
  }

  updateDataTable(data){
    const table = document.getElementById('upsold_product_table')
    const template = document.getElementById('upsold_product_row')
    if(!table || !template) return

    const tbody = table.querySelector('tbody')

    this.clearTable(tbody)

    data.forEach((row,i) => {
      const clone = template.content.cloneNode(true)
      let td = clone.querySelectorAll('td')
      td[0].textContent = i + 1
      td[0].classList.add('text-center')
      td[1].textContent = row.product_title
      td[2].textContent = row.variant_title
      td[3].textContent = row.revenue
      td[3].classList.add('text-right')
      td[4].textContent = row.pc_upsell_revenue
      td[4].classList.add('text-right')
      td[5].textContent = row.quantity
      td[5].classList.add('text-right')
      tbody.appendChild(clone)
    })
  }

  clearTable(node){
    var child = node.lastElementChild
    while (child) {
      node.removeChild(child)
      child = node.lastElementChild
    }
  }

}
