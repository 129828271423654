import Rails from "@rails/ujs";
import Cookie from 'js-cookie';
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'testEmailForm', 'emailErrorNotification', 'skipEmailTestBtn', 'sendAnotherTestBtn', 'sentCheckIcon' ]

  connect(){
    this.checkTestAlreadySent();
  }

  resetCookie(){
    Cookie.remove('test_email_sent')
  }

  setEmailTestCookie(){
    Cookie.set('test_email_sent', true);
  }

  submitTestEmail(){
    Rails.fire(this.element, 'submit')
  }

  onSendTest(event){
    let [data, status, xhr] = event.detail;
    let status_response = JSON.parse(xhr.response)["status"];
    status_response == 'success' ? this.sendTestSuccess() : this.sendTestError();
  }

  sendTestSuccess(){
    this.setEmailTestCookie();
    document.location.reload();
  }

  sendTestError(){
    this.emailErrorNotificationTarget.style.display = 'block';
  }

  sendAnotherTest(){
    this.resetCookie();
    this.checkTestAlreadySent();
  }

  skipEmailTest(){
    this.setEmailTestCookie();
    document.location.reload();
  }

  checkTestAlreadySent(){
    let sent_email_cookie = Cookie.get('test_email_sent');
    if (sent_email_cookie == null) {
      this.emailErrorNotificationTarget.style.display = 'none';
      this.sendAnotherTestBtnTarget.style.display = 'none';
      this.sentCheckIconTarget.style.display = 'none';
      this.testEmailFormTarget.style.display = 'block';
      this.skipEmailTestBtnTarget.style.display = 'flex';
    }else{
      this.emailErrorNotificationTarget.style.display = 'none';
      this.sendAnotherTestBtnTarget.style.display = 'flex';
      this.sentCheckIconTarget.style.display = 'flex';
      this.testEmailFormTarget.style.display = 'none';
      this.skipEmailTestBtnTarget.style.display = 'none';
    }
  }

}
