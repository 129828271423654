import { Controller } from 'stimulus' 

export default class extends Controller {
  static targets = ['name', 'item']
  static values = { 
    setting: Object,
    item: Object
  }

  connect() {
    this.element[this.identifier] = this
  }
  
  mutate(behavior) {
    switch(behavior) {
      case 'soldOut':
        this.soldOut()
        break
      case 'limit':
        this.limit()
        break
      default:
        this.addToCart()
    }
  }

  addToCart() {
    this.element.innerHTML = this.settingValue.add_to_cart
    this.element.setAttribute("data-action", "checkout#tossMore")
    this.element.removeAttribute('disabled')
  }

  limit() {
    this.element.innerHTML = this.settingValue.limit_for_free_products
    this.element.setAttribute('disabled', true)
    this.element.removeAttribute('data-action')
  }

  soldOut() {
    this.element.innerHTML = this.settingValue.sold_out
    this.element.setAttribute('disabled', true)
    this.element.removeAttribute('data-action')
  }
}