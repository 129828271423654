import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'panel', 'select' ]
  
  connect() {
   const initialState = this.selectTarget.value
   this.loadPanel(initialState)
  }

  changePanel(event) {
    this.loadPanel(event.target.value)
  }

  loadPanel(panel) {
    this.panelTargets.forEach((panelTarget, index) => {
      let panelName = panelTarget.getAttribute("data-panel") 
      if (panel == panelName) {
        panelTarget.style.display = 'block'
      } else {
        panelTarget.style.display = 'none'
      }
    })
  }

}
