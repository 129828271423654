import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selection", "noSelection", "selectionContainer","add_item", "template"]

  static values = {
    triggable: String
  }
  
  selectItem(event) {
    this.addSelectItem(event.currentTarget)
  }

  addSelectItem(element) {
    this.noSelectionTarget.classList.add('filter--notMatch')
    this.selectionContainerTarget.classList.remove('filter--notMatch')

    const virtualSelect = this.selectionTarget

    const el = element.cloneNode(true)
    const btn_remove = document.createElement("span")
    btn_remove.setAttribute("class", "mdc-list-item__meta")
    btn_remove.setAttribute("aria-hidden", "true")
    btn_remove.innerHTML = '<a rel="nofollow" style="font-size: 1.25em"><i class="far fa-times-circle"></i></a>'

    let template_id = new Date().valueOf()
    
    btn_remove.dataset.action = "click->product-select#removeItem"
    btn_remove.dataset.itemIndex = template_id
    el.dataset.action = ''
    el.appendChild(btn_remove)

    virtualSelect.appendChild(el)
    this.add_association(element,template_id)
  }

  removeItem(event){  
    let item_index = event.currentTarget.dataset.itemIndex
    document.getElementById(this.element_id_prefix() +item_index+"__destroy").value = true
    event.currentTarget.parentNode.remove()
  }

  add_association(element,template_id) {
    console.log(element.dataset)
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, template_id)
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    document.getElementById(this.element_id_prefix() + template_id+"_featured_image_src").value = element.dataset.featuredImageSrc
    document.getElementById(this.element_id_prefix() + template_id+"_gid_uri").value = element.dataset.gidUri
    document.getElementById(this.element_id_prefix() + template_id+"_product_title").value = element.dataset.productTitle
    document.getElementById(this.element_id_prefix() + template_id+"_variant_title").value = element.dataset.variantTitle
    document.getElementById(this.element_id_prefix() + template_id+"_is_variant").value = element.dataset.isVariant
  }

  element_id_prefix() {
    return this.triggableValue + '_trigger_items_attributes_'
  }

}
