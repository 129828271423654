import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'selectiveSendOptions', 'sendTimeWindowOptions' ]
  
  toggleSelectiveSend(event) {
    if(event.currentTarget.checked) {
      document.getElementById('email_setting_selective_tags_enabled').value = 'true'
      this.selectiveSendOptionsTarget.classList.remove('filter--notMatch')
    }
    else {
      document.getElementById('email_setting_selective_tags_enabled').value = 'false'
      this.selectiveSendOptionsTarget.classList.add('filter--notMatch')
    }
  }

  toggleSendTimeWindow(event) {
    if(event.currentTarget.checked) {
      this.sendTimeWindowOptionsTarget.classList.remove('filter--notMatch')
    }
    else {
      this.sendTimeWindowOptionsTarget.classList.add('filter--notMatch')
    }
  }
}
