import './index.scss';
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = ['wrapper', 'selectBtn'];
  static values = { selected: String, selectedId: String };

  connect() {
    document.addEventListener('click', this.clickOutsideCallback); 
  }

  selectBtnClick(event) {
    this.wrapperTarget.classList.toggle(`c-${this.identifier}-active`);
  }

  selectItem(selectedLi) {
    this.hideSelectContent();
    this.selectedValue = selectedLi.currentTarget.dataset.value;
    this.selectedIdValue = selectedLi.currentTarget.dataset.id;
  }

  selectedValueChanged(value) {
    if(!value) return;
    this.selectBtnTarget.firstElementChild.innerText = value; 
  }

  hideSelectContent() {
    this.wrapperTarget.classList.remove(`c-${this.identifier}-active`);
  }

  clickOutsideCallback = (event) => {
    if (!this.wrapperTarget.contains(event.target)) {
      this.hideSelectContent();
    }
  } 
}
