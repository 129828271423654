/*
  ARPU JS Utils
  add any function or class to be imported as
  import { whatever_function, WhateverClass } from "../packs/arpu_utils.js"
  -- (when importing... make sure the path is correct) 
*/

/* This function provides a way to format currency values based on the store configuration */
export function arpu_currency_formatting(value, prefix, format, suffix, currency_code) {

  let number = Number(value)
  let num = number.toFixed(2)
 
  /* Checks if number format is one of the availables
    liquid variables for currency formatting on a Shopify Shop.
    For other platforms it uses the default, based on
    the store's currency code (ISO 4217).
  */
  switch (format) {
    case 'amount':
      num = number.toFixed(2)
      break
    case 'amount_no_decimals':
      num = number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      break
    case 'amount_with_comma_separator':
      num = number.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      break
    case 'amount_no_decimals_with_comma_separator':
      num = number.toFixed(0).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      break
    case 'amount_with_apostrophe_separator':
      num = number.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")
      break
    default:
      if(currency_code!==undefined) {
        return Intl.NumberFormat(undefined, {
                 style: 'currency',
                 currency: currency_code
               }).format(number)
      }
      else {
        prefix = '$'
        suffix = ''
      }
  }
  return prefix + num + suffix
}

export function arpu_number_from_currency_formatting(value, format) {
  let number = Number(value)
  let num = number.toFixed(2)
  switch (format) {
    case 'amount_no_decimals':
    case 'amount_no_decimals_with_comma_separator':
      num = number.toFixed(0)
      break
    default:
      num = number.toFixed(2)
  }
  return Number(num)
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function arpu_showNotificationError(message) {
  const notification_box = document.getElementById('notification-box')
  const error_div = document.createElement("div")
  error_div.classList.add('notification')
  error_div.classList.add('notification--error')
  const error_message = document.createTextNode(message)
  error_div.appendChild(error_message)
  notification_box.appendChild(error_div)  
}

export function arpu_validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function observeDOM(options, obj, callback) {
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
  if( !obj || obj.nodeType !== 1 ) return; 

  if( MutationObserver ){
    const mutationObserver = new MutationObserver(callback)

    mutationObserver.observe( obj, options )
      return mutationObserver
  }
}

export function jsonRequestOptions(options={}) {
  const token = document.head.querySelector("[name~=csrf-token][content]")
  const csrfToken = token ? token.content : ''
  const defaultOptions = {
    dataType: "json",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken
    }
  };

  return { ...options, ...defaultOptions };
}
