import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['exportModal', 'dateRange', 'report']
  static values = {
    url: String
  }

  connect() {
    setTimeout(() => {
      this.retrieveReportStatus()
    }, 5000)
  }

  toggleExportOn() {
    this.exportModalTarget.style.display = 'block'
  }

  toggleExportOff() {
    this.exportModalTarget.style.display = 'none'
  }

  retrieveReportStatus(){
    let in_progress = false

    for(var i=0; i < this.reportTargets.length ; i++) {
      const tr = this.reportTargets[i]
      if(tr.dataset.attached == 'true' ) continue 
      else { in_progress = true }
      this.getStatus(tr.dataset.report)
    }

    if(in_progress) {
      setTimeout(() => {
        this.retrieveReportStatus()
      }, 20000)
    }
  }

  getStatus(id) {
    let url = '/analytics_reports/' + id + '/status'
    let token = document.head.querySelector("[name~=csrf-token][content]")
    fetch(url, {
      headers: { 'X-CSRF-Token': token }
    })
    .then(response => response.text())
    .then(data => {
      this.updateStatus(id, data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  updateStatus(id, data) {
    for(var i=0; i < this.reportTargets.length ; i++) {
      const tr = this.reportTargets[i]
      if(tr.dataset.report == id ) { 
        tr.innerHTML = data
      }
    }
  }

}
