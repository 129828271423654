import { Controller } from "stimulus"

import { 
  Chart, BarElement, BarController, LineElement, LineController,
  PointElement, CategoryScale, LinearScale, Tooltip
} from 'chart.js'
import { observeDOM } from '../packs/arpu_utils';

export default class ChartController extends Controller {

  static targets = [ "chart" ]

  connect() {
    Chart.register(
      LineElement,
      LineController,
      PointElement,
      CategoryScale,
      LinearScale,
      Tooltip,
    )
    Chart.register({
      id: 'mouseoutCatcher',
      beforeEvent: (chart, args, pluginOptions) => {
        const event = args.event;
        if (event.type === 'mouseout') {
          chart.update('none')
        }
      }
    })
    this.render()
    if(this.filterElement) {
      observeDOM({ attributes: true }, this.filterElement, this.filterCallback)
    }
  } 

  filterCallback = (entries) => {
    entries.forEach((entry) => {
      if(entry.attributeName == 'data-period-filter-url-response-value') {
        this.updateGraph(entry.target.dataset.periodFilterUrlResponseValue)
      }
    })
  }

  updateGraph(response) {
    if(!this.graph || !response) return
    try {
      this._metrics = JSON.parse(response)['metrics_versions']
      this._labels = this.calculateLabels()
      this.graph.data = this.data
      this.graph.update()
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    if (!this.ele) return
   
    const ctx = this.ele.getContext('2d')
    
    this.graph = new Chart(ctx, { type: 'bar', data: this.data, options: this.options })
  }

  get url() {
    return this._url = this._url || this.element.dataset.url.split("?")[0]
  }

  get filterElement() {
    return this._filterElement = this._filterElement || document.querySelector('[data-controller="period-filter"]')
  }

  get ele() {
    return this._ele = this._ele || this.chartTarget
  }

  get metrics() {
    return this._metrics = this._metrics || JSON.parse(this.element.dataset.metricsVersions)
  }

  get currency_symbol() {
    return this._currency_symbol = this._currency_symbol || this.element.dataset.currency
  }

  get options() {
    return { 
      responsive: true, 
      interaction: {
        intersect: false,
        mode: 'index',
      }, 
      maintainAspectRatio: false, 
      scales: {
        y: { 
          type: 'linear',
          grace: '10%',
          position: 'left',
          grid: { display: true }, 
          ticks: { 
            callback: (value, index, ticks) => { return this.currency_symbol + value } 
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        'mouseoutCatcher': false,
        tooltip: {
          enabled: true,
          position: 'nearest',
          displayColors: false,
          backgroundColor: 'rgba(0,0,0,0.9)',
          filter: (tooltipItem, data) => {
            if (tooltipItem.dataIndex == 0 || (tooltipItem.dataIndex + 1) == this.labels.length) {
              return false
            } 

            return true
          },
          callbacks: {
            title: (context) => {
              if(context.length == 0) return null
              const position = context[0].parsed.x
              const startPoint = this.labels[position]
              if(startPoint) {
                let endPoint = this.metrics[position].end_date
                if(startPoint == endPoint) { endPoint = 'Today' }
                return `${startPoint} to ${endPoint}`
              }
            },
            label: (context) => {
              if(this.pointedVersion(context.parsed.x)) {
                let label = context.dataset.label || ''
                if (label) {
                 label += ': '
                  label += this.currency_symbol
                  label += this.revenueVersionByIndex(context.parsed.x)
                }
                return label
              }
            }
          }
        }
      },
    }
  }

  revenueVersionByIndex(index) {
    const metric = this.metrics[index]
    if(metric) {
      return parseInt(metric.revenue, 10)
    }
  }

  get data() {
    return { labels: this.labels, datasets: this.datasets }
  }

  calculateLabels() {
    const labels = this.metrics.map((m,index) => {
      return this.pointedVersion(index) ? m.start_date : ''
    })
    return labels
  }

  pointedVersion(position) {
    return position !== 0 && position !== (this.metrics.length - 1)
  }

  get labels() {
    return this._labels = this._labels || this.calculateLabels()
  }

  get versions_datasets() {
    return {
      label: 'Revenue Version',
      data: this.metrics.map((m) => parseInt(m.revenue, 10)),
      type: 'line',
      borderColor: 'rgb(75, 192, 192)',
      borderWidth: 5,
      tension: 0.1,
      fill: false,
      elements: {
        point: { 
          pointHoverRadius: this.versionsIconPosition,
          pointRadius: this.versionsIconPosition,
          pointStyle: this.versionIcon
        }
      }
    }
  }

  get versionsIconPosition() {
    return this.metrics.map((version, index, versions) => {
      if(index !== 0 && index !== (versions.length - 1)) {
        return 5
      } else {
        return 0
      }
    })
  }

  get versionIcon() {
    const versionIcon = document.getElementById('version-icon-svg')
    if(versionIcon) {
      versionIcon.width = 20;
      versionIcon.height = 20;
    }
    return versionIcon
  }

  get datasets() {
    return [ this.versions_datasets ]
  }
}
