import { Controller } from "stimulus"
import { jsonRequestOptions } from "../packs/arpu_utils"

export default class extends Controller {
  static targets = [ 'select' ]
  static values = { url: String, current: String }

  connect() {
    this.selectPreviousTarget()
  }

  update(event) {
    const data = { store: { locale: event.target.value } }
    const opts = { method: 'PATCH', body: JSON.stringify(data) }

    fetch(this.urlValue, jsonRequestOptions(opts))
      .then(response => response.ok)
      .catch((error) => { console.log('error', error) })
  }

  selectPreviousTarget() {
    if(this.hasSelectTarget && this.hasCurrentValue) {
      this.selectTarget.querySelector(`option[value=${this.currentValue}`).selected = 'selected';
    }
  }
}
