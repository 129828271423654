import { arpu_currency_formatting, arpu_number_from_currency_formatting } from "../arpu_utils.js"

export default class CurrencyFormatter {
  constructor(prefix, moneyFormat, suffix, currencyCode) {
    this.prefix = prefix;
    this.moneyFormat = moneyFormat;
    this.suffix = suffix;
    this.currencyCode = currencyCode;
  }

  format(value) {
    let n_value = Number(value);
    return (n_value==0) ? "" : arpu_currency_formatting(n_value, this.prefix, this.moneyFormat, this.suffix, this.currencyCode);
  }

  number_from_currency(value) {
    const format = this.moneyFormat;
    return (format === undefined) ? value : arpu_number_from_currency_formatting(value, format);
  }
}
