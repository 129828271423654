import { Controller } from 'stimulus'

class CollapseController extends Controller {
  connect() {
    this.includeInputsActions()
  }
  
  toggleBlock(event) {
    const toggleElement = event.currentTarget.closest('.collapse-item')
    const iconTarget = toggleElement.querySelector('.clickable-menu > i')
    this.collapseAll(toggleElement)
    toggleElement.classList.toggle('expand-item')
    if (iconTarget) iconTarget.classList.toggle('rotate')
  }

  toggleFromTab(event) {
    const code = (event.keyCode ? event.keyCode : event.which);
    const isExpanded = event.currentTarget.closest('.collapse-item').classList.contains('expand-item')
    
    if (code == 9 && !isExpanded) {
      this.toggleBlock(event)
    } 
  }

  collapseAll(toggleElement) {
    Array.from(document.querySelectorAll('.collapse-item')).forEach((collapseTarget) => {
      if (collapseTarget === toggleElement) return
      if (collapseTarget.classList.contains('expand-item')) {
        const iconTarget = collapseTarget.querySelector('.clickable-menu > i')
        collapseTarget.classList.remove('expand-item')
        if (iconTarget) iconTarget.classList.remove('rotate')
      }
    })
  }

  includeInputsActions() {
    let inputs = Array.from(document.querySelectorAll('.collapse-item')).reduce((arr, collapseMenu) => {
      const inputGroups = collapseMenu.querySelectorAll('.input-group')
      return [...arr, inputGroups[0].lastElementChild, inputGroups[inputGroups.length-1].lastElementChild]
    }, [])
    inputs.forEach((input) => {
      if(!input) return
      if (input.dataset.action) {
        const action = `${input.dataset.action} keyup->collapse#toggleFromTab`
        input.setAttribute('data-action', action)
      } else {
        input.setAttribute('data-action', 'keyup->collapse#toggleFromTab')
      }
    })
  }
}

export default CollapseController