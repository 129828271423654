import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  connect() {}

  activeTab(event) { 
    let el = event.currentTarget;
    [...el.parentElement.children].forEach(sib => sib.classList.remove('c-card-is-active'));
    el.classList.add('c-card-is-active');
  }
}
