import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    data: Object
  }

  static targets = [ 'revenue', 'total', 'delaysEmails']

  connect(){
    this.updateData()
  }

  updateData(){
    this.updateTotals(this.dataValue)
    this.updateDataTable(this.dataValue.rank)
  }

  updateTotals(data){
    this.revenueTarget.innerHTML = data.revenue
    this.totalTarget.innerHTML = data.total
    this.delaysEmailsTarget.innerHTML = data.delays_emails
  }

  updateDataTable(data){
    const table = document.getElementById('delay_product_table')
    const template = document.getElementById('delay_product_row')

    const tbody = table.querySelector('tbody')

    this.clearTable(tbody)

    data.forEach((row,i) => { 
      const clone = template.content.cloneNode(true)
      let td = clone.querySelectorAll('td')
      td[0].textContent = i + 1
      td[1].textContent = row.table.product
      td[2].textContent = row.table.variant
      td[3].textContent = row.table.revenue
      td[4].textContent = row.table.total
      td[5].textContent = row.table.delays_email
      tbody.appendChild(clone)
    })
  }

  clearTable(node) {
    var child = node.lastElementChild 
    while (child) {
      node.removeChild(child)
      child = node.lastElementChild
    }
  }
}
