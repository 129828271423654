import { Controller } from "stimulus"
import { arpu_validateEmail } from "../packs/arpu_utils.js"

export default class extends Controller {

  static targets = [ 'response', 'emailField', 'btnAction' ]
  static values = { 
    url: String
  }

  submitTestEmail() {

    this.toggleAction()

    let email =  this.emailFieldTarget.value

    if(!arpu_validateEmail(email)) {
      this.displayResponse('🙈 Please, provide a valid email address.')
      return
    }

    let formData = new FormData()
    formData.append('email', email)

    fetch(this.urlValue, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.head.querySelector("[name~=csrf-token][content]").content
      }
    })
    .then(response => {
      if (response.ok) {
        this.responseOk()
        this.toggleAction()
      }
      else {
        response.json().then(data => {
          this.responseError(data)
          this.toggleAction()
        })
      }
    })
    .catch(error => {
      this.responseError('Error: ' + error)
    })
  }

  checkInput(){
    let email =  this.emailFieldTarget.value
    if(email.length < 1) {
      this.clearDisplayResponse()
    }
  }

  clearDisplayResponse(){
    this.displayResponse('&nbsp;')
  }

  displayResponse(message) {
    this.responseTarget.innerHTML = '<p>'+ message +'</p>'
  }

  responseOk() {
    this.displayResponse('👍 Message sent.')
  }

  responseError(error) {
    this.displayResponse(error.message)
  }

  toggleAction() {
    this.btnActionTarget.disabled = !this.btnActionTarget.disabled
  }
}
