import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ 'imageSource', 'imagePreview' ]

  readURL(input) {
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = (e) => {
        this.imagePreviewTarget.src = e.target.result
      }
      reader.readAsDataURL(input.files[0])
    }
  }

  loadPreview(event) {
    const input = event.currentTarget
    this.readURL(input)
  }

  updateWidth(event) {
    const width = event.currentTarget
    this.imagePreviewTarget.style.width = width.value + 'px'
  }

}
