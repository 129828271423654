import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["delayOptions", "calendarOptions", "shipNowOptions", "delayOptionsLabel", "template", "addLinksHere", "linkOption", "linkOptionLabel", "linkOptionIndex", "saveButton"]

  formIsChanged(event) {
    this.setChanged("true")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
    this.saveButtonTarget.classList.remove('filter--notMatch')
  }

  toggleOptions(event) {
    this.setChanged("true")
    if (event.currentTarget.checked) {
      this.delayOptionsLabelTarget.innerHTML = "Delay Flow Enabled"
      document.getElementById('delay_setting_delay_enabled').value = 'true'
    }
    else {
      this.delayOptionsLabelTarget.innerHTML = "Delay Flow Disabled"
      document.getElementById('delay_setting_delay_enabled').value = 'false'
    }
    this.toggleNode(this.delayOptionsTarget, event.currentTarget.checked)
  }

  toggleCalendarOptions(event) {
    this.setChanged("true")
    this.toggleNode(this.calendarOptionsTarget, event.currentTarget.checked)
  }

  toggleShipNowOptions(event) {
    this.setChanged("true")
    this.toggleNode(this.shipNowOptionsTarget, event.currentTarget.checked)
    this.toggleNode(this.shipLanguageOptionsTargets, event.currentTarget.checked, true)
  }

  toggleNode(target, value, array = false) {
    if (value) {
      if (array) {
        target.forEach((entry) => {
          entry.classList.remove('filter--notMatch')
        })
      } else {
        target.classList.remove('filter--notMatch')
      }
    } else {
      if (array) {
        target.forEach((entry) => {
          entry.classList.add('filter--notMatch')
        })
      } else {
        target.classList.add('filter--notMatch')
      }
    }
  }

  addDelayOption(event) {
    let template_id = new Date().valueOf()
    let element_count = this.linkOptionTargets.length
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, template_id)
    content = content.replace(/INDEX/g, element_count)
    content = content.replace(/BUTTON_NUMBER/g, element_count + 1)
    this.addLinksHereTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeDelayOption(event) {
    const element = event.currentTarget
    let index = element.dataset.index
    if (this.linkOptionTargets[index].dataset.persisted == 'true') {
      document.getElementById(`delay_setting_delay_link_options_attributes_${index}__destroy`).value = true
    }
    this.linkOptionTargets[index].remove()
    this.reIndex()
  }

  reIndex() {
    for (let i = 0; i < this.linkOptionTargets.length; i++) {
      let j = i + 1
      this.linkOptionLabelTargets[i].innerHTML = `Button #${j}`
      this.linkOptionIndexTargets[i].dataset.index = i
    }
  }
}
