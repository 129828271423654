import { Controller } from "stimulus"
import { DateTime } from 'luxon';
import { observeDOM } from '../../javascript/packs/arpu_utils';

export default class extends Controller {
  static targets = ['periodFilter', 'periodGroup', 'spinning']

  connect() {
    if(this.hasPeriodFilterTarget) {
      observeDOM({ attributes: true }, this.periodFilterTarget, this.periodFilterCallback);
    }
  }

  periodFilterCallback = (changes) => {
    if(this.hasPeriodGroupTarget && this.hasPeriodFilterTarget) {
      changes.forEach((change) => {
        if(change.attributeName == 'data-period-filter-filtered-value') {
          this.setAvailableGroups();
        }
        if(change.attributeName == 'data-period-filter-url-response-value') {
          this.spinningTarget.classList.add('filter--notMatch');
        }
      })
    }
  }

  setAvailableGroups() {
    const groupsEls = Array.from(this.periodGroupTarget.querySelector('#groups').children)
    const monthEl = groupsEls[groupsEls.length - 1]
    if(monthEl) {
        monthEl.querySelector('button').disabled = (this.filteredInterval(this.intervalRange) < 2)
        this.spinningTarget.classList.remove('filter--notMatch')
        setTimeout(() => this.spinningTarget.classList.add('filter--notMatch'), 2000)
    }
  }

  disablePeriodGroupButton(el) {
    el.querySelector('button').disabled = (this.filteredInterval(this.intervalRange) < 2);
  }

  filteredInterval(dates) {
    if (typeof dates === 'string' && dates.trim() !== '') {
      const rangeDate = dates.split(' to ')
      const startDate = DateTime.fromISO(rangeDate[0])
      const endDate = DateTime.fromISO(rangeDate[1])
      return endDate.diff(startDate, ['months']).toObject()['months']
    }
  }

  get intervalRange() {
    return this.periodFilterTarget.dataset.periodFilterFilteredValue
  } 
}
