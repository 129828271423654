import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['contactButton']

  feedback() {
    this.track('clicked feedback')
  }

  contact() {
    this.track('clicked contact')
  }

  track(event) {
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    fetch(`/track_event?event=${event}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
  }
}