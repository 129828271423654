import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'historyModal', 'historyTitle', 'historyDescription', 'historyTable']

  connect(){}

  loadDetailedTable(e) {
    this.historyTableTarget.table.dataValue = JSON.parse(e.currentTarget.dataset.history)
    this.historyTableTarget.table.updateData()
    this.updateDescription(JSON.parse(e.currentTarget.dataset.historyDetails))
    this.historyModalTarget.modal.show(e)
  }

  updateDescription(history) {
    this.historyTitleTarget.innerHTML = ''
    this.historyDescriptionTarget.innerHTML = ''
    if(!history) return

    this.historyTitleTarget.innerHTML = history.title
    this.historyDescriptionTarget.innerHTML = `${history.count} of the email addresses had problem when ARPU attempted to send notifications on ${history.date}.` 
  }
}
