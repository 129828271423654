import { Controller } from "stimulus"
import { CurrencyFormatter } from "../packs/checkout"

export default class extends Controller {

  static targets = [ "subscriptionPrice"]

  static values = { itemId: Number, price: Number, salePrice: Number }

  initialize() {
    const config = document.getElementById('checkout').dataset
    this.currencyFormatter = new CurrencyFormatter(
      config.checkoutMoneyFormattingPrefixValue,
      config.checkoutMoneyFormattingJsNumberFormatValue,
      config.checkoutMoneyFormattingSuffixValue,
      config.checkoutCurrencyValue
    )
  }

  updateDiscount(event) {
    if(this.priceValue == this.salePriceValue) {
      const element = event.currentTarget
      const option = element.options[element.selectedIndex].value
      if (option) {
        let item = JSON.parse(option)
        let discount = parseFloat(item.discount_amount)

        if(discount > 0) {
          let price = this.priceValue
          let sale_price = price
          if(item.discount_type == 'percentage') {
            sale_price = price * (1 - discount / 100.0)
          }
          else {
            sale_price = price - discount
          }
          this.showDiscount(price, sale_price)
        }
      }
    }
  }

  showDiscount(price, sale_price) {
    while (this.subscriptionPriceTarget.firstChild) {
      this.subscriptionPriceTarget.removeChild(this.subscriptionPriceTarget.firstChild);
    }

    const sale = document.createElement('span')
    sale.classList.add('money', 'sale')
    sale.innerHTML = this.currencyFormatter.format(sale_price) + ' '

    const old_price = document.createElement('span')
    old_price.classList.add('money', 'compare-at')
    old_price.innerHTML = ' ' +  this.currencyFormatter.format(price)

    this.subscriptionPriceTarget.appendChild(sale)
    this.subscriptionPriceTarget.appendChild(old_price)
  }

}
