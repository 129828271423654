import { Controller } from "stimulus"
import { Cashier, CurrencyFormatter } from "../packs/checkout"

export default class extends Controller {

  static targets = ["cart", "subtotal", "cartEmpty", "cartFooter", "validationError", "oneTimeExtras", "subscriptionExtras", "cartCount", "cartItem", "qty", "processing", "product", "error", "notification", "subscriptionEmpty", "cartEmptyMsg", "subscriptionSchedule", "variantOptions"]

  static values = {
    oneTimeExtras: Array,
    purchaseUrl: String,
    subscribeUrl: String,
    initialProduct: Number,
    initialPurchaseType: String,
    moneyFormattingJsNumberFormat: String,
    moneyFormattingPrefix: String,
    moneyFormattingSuffix: String,
    currency: String,
    setting: Object,
    otpsMade: Number,
    otpsLimit: Number,
    freeItemsLimit: String
  }

  initialize() {
    this.currencyFormatter = new CurrencyFormatter(
      this.moneyFormattingPrefixValue,
      this.moneyFormattingJsNumberFormatValue,
      this.moneyFormattingSuffixValue,
      this.currencyValue
    );
    this.oneTimeCashier = new Cashier([], this.currencyFormatter);
    this.subscriptionCashier = new Cashier([], this.currencyFormatter);
  }

  connect() {
    this.addPreselectedItem()
  }

  getToken() {
    let token = document.head.querySelector("[name~=csrf-token][content]")
    return (token) ? token.content : ""
  }

  addToNextOrder(event) {
    this.startProcessing()
    if (this.oneTimeCashier.lineItemsCount > 0) {
      let formData = new FormData()
      let items = this.oneTimeCashier.lineItems.flatMap(x => [{ id: x.id, qty: x.qty, featured: x.featured }])
      formData.append('items', JSON.stringify(items))
      fetch(this.purchaseUrlValue, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': this.getToken()
        }
      })
        .then(response => {
          if (response.ok) {
            this.addToSubscription(response)
          } else {
            let errorRedirect = response.headers.get('X-Ajax-Redirect-Url')
            if (!!errorRedirect) {
              window.location.href = errorRedirect
            } else {
                this.stopProcessing()
              if (response.status == 422) {
                response.json().then((body) => {
                  this.showValidationError(body.error) 
                });
              } else {
                this.showErrorMessage()
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.stopProcessing()
        })
    } else {
      this.addToSubscription(null)
    }
  }

  // This method is called when adding subscription
  // to an existing subscription. It will copy the susbscription preferences
  // over to the subscription purchase object.
  subscriptionWithPreferences() {
    let prefs = {}
    this.subscriptionScheduleTargets.forEach(option => {
      let parsed_option = JSON.parse(option.value)
      let index = parsed_option.item_id
      delete parsed_option.item_id
      prefs[index] = parsed_option
    })
    let subscriptionExtraValues = this.subscriptionCashier.lineItems
    subscriptionExtraValues.forEach(item => {
      item['subscription_preferences'] = prefs[item.id]
    })
    return subscriptionExtraValues
  }

  addToSubscription(previousResponse) {
    if (this.subscriptionCashier.lineItemsCount > 0) {
      let formData = new FormData()
      let items = this.subscriptionWithPreferences().flatMap(x => [{ id: x.id, qty: x.qty, featured: x.featured, subscription_preferences: x.subscription_preferences }])
      formData.append('items', JSON.stringify(items))
      fetch(this.subscribeUrlValue, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': this.getToken()
        }
      })
        .then(response => {
          if (response.ok) {
            // All subscriptions succeeded
            if (response.redirected) {
              let redirect_to = previousResponse ? previousResponse.url : response.url
              window.location.href = redirect_to
            } else {
              return this.renderPreviewThankYou(response)
            }
          } else {
            // At least one subscription failed
            if (previousResponse && previousResponse.redirected) {
              // We already have a path to redirect to
              window.location.href = previousResponse.url
            } else {
              // We only have subscriptions on cart, show the error
              this.stopProcessing()
              this.showErrorMessage()
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.stopProcessing()
        })
    } else {
      if (previousResponse && previousResponse.redirected) {
        window.location.href = previousResponse.url
      } else {
        this.renderPreviewThankYou(previousResponse)
      }
    }
  }

  renderPreviewThankYou(response) {
    response.text()
      .then(data => {
        if (data !== undefined) {
          document.open()
          document.write(data)
          document.close()
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.stopProcessing()
      })
  }

  showErrorMessage() {
    this.oneTimeCashier.clear();
    this.subscriptionCashier.clear();
    this.cartTarget.classList.add('filter--notMatch')
    this.errorTarget.classList.add('visible')
  }

  canAddMore() {
    if (this.otpsLimitValue == 0) {
      return true
    }
    let otp_count = this.oneTimeCashier.lineItemsCount;
    return this.otpsLimitValue > (this.otpsMadeValue + otp_count)
  }

  showValidationError(err='') {
    Array.from(this.validationErrorTarget.children).forEach(node => node.remove())
    const errNode = document.createElement('p')
    errNode.innerHTML = err
    this.validationErrorTarget.appendChild(errNode)
    this.validationErrorTarget.classList.remove("filter--notMatch")
  }
  
  cartFull() {
    // TODO: should this target exist?
    //this.cartFullTarget.classList.remove("filter--notMatch")
    this.qtyTargets.map(e => e.setAttribute("disabled", "disabled"))
    this.qtyPlusTargets.map(e => e.setAttribute("disabled", "disabled"))
    this.showCart()
  }

  cartNotFull() {
    // TODO: should this target exist?
    //this.cartFullTarget.classList.add("filter--notMatch")
    //this.qtyTargets.map(e => e.removeAttribute("disabled", "disabled"))
    //this.qtyPlusTargets.map(e => e.removeAttribute("disabled", "disabled"))
  }

  /* actions */
  tossMore(event) {
    const extraItem = JSON.parse(event.currentTarget.getAttribute("data-item"))
    this.addToCart(extraItem, 'otp', this.variantOptions(event.currentTarget.parentElement))
  }

  tossMoreToSubscription(event) {
    const extraItem = JSON.parse(event.currentTarget.getAttribute("data-item"))
    this.addToCart(extraItem, 'subscription', this.variantOptions(event.currentTarget.parentElement))
  }

  addPreselectedItem() {
    let selectedProduct = this.productTargets.find(target => target.getAttribute('data-item-id') == this.initialProductValue)
    if (selectedProduct !== undefined) {
      const extraItem = JSON.parse(selectedProduct.getAttribute('data-item'))
      this.addToCart(extraItem, this.initialPurchaseTypeValue, this.variantOptions(selectedProduct.parentElement))
    }
  }

  variantSelected(event) {
    const element = event.currentTarget
    const option = element.options[element.selectedIndex]
    let variantItem = JSON.parse(option.dataset.object)
    let addToCartButton = window.document.querySelector(`button[data-item-id="${variantItem.featured_item_id}"]`)
    let addToCartLink = window.document.querySelector(`a[data-item-id="${variantItem.featured_item_id}"]`)
    if (addToCartLink)
      addToCartLink.setAttribute("data-item", JSON.stringify(variantItem))
    if (!(option.dataset.soldOut == "false")) {
      addToCartButton['checkout-button'].mutate('soldOut')
    }
    else {
      addToCartButton['checkout-button'].mutate('addToCart')
      addToCartButton.setAttribute("data-item", JSON.stringify(variantItem))
    }
    let featureItemElement = document.querySelector(`.product-grid-item[data-item='${variantItem.featured_item_id}']`)
    featureItemElement['variant-select'].optionSelected(event)
  }

  addToCart(extraItem, type = 'otp', selectOptions = null) {
    this.freeItemCheck(extraItem, type);

    const cashier = (type == 'otp') ? this.oneTimeCashier : this.subscriptionCashier

    if (this.freeItemsLimitValue == 'true' && cashier.isFree(extraItem)) {
      // Free item limit per order
      const item_otp = this.oneTimeCashier.lineItems.find(e => this.oneTimeCashier.isFree(e))
      const item_sub = this.subscriptionCashier.lineItems.find(e => this.subscriptionCashier.isFree(e))
      if (!!item_otp) { 
        // We already have an free item as OTP. Let's remove the first free item so we can replace it with this one.
        this.oneTimeCashier.remove(item_otp)
        this.removeCartItem(item_otp.id, 'otp')
      } else if (!!item_sub) {
        // We already have an free item as subscription. Let's remove the first free item so we can replace it with this one.
        this.subscriptionCashier.remove(item_sub)
        this.removeCartItem(item_sub.id, 'subscription')
      }
    }

    if (cashier.scan(extraItem, 1)) {
      const _item = cashier.findLineItem(extraItem)
      this.updateQtyWidget(_item.id, _item.qty, type)
      this.showCart()
    }
    else {
      cashier.include(extraItem, 1)
      this.buildHTMLForExtraItem(extraItem, type, selectOptions)
    }
    this.updateCartItemsCount()
    this.updateSubtotal()
  }

  /*
    add to existing subscription...
    This method is invoked when a subscription schedule plan is selected.
    The subscription plan might have a discount setup with it.
    Then we must update the subtotal.
  */
  updateSubscriptionPrice(event) {
    const element = event.currentTarget
    if(element.options.length > 0){
      const data = JSON.parse(element.options[element.selectedIndex].value)
      if(data.discount_amount > 0) {
        const cashier = this.subscriptionCashier
        const item = cashier.findLineItem({ id: data.item_id, featured: data.item_type})
        cashier.applyDiscount(item, data.discount_amount, data.discount_type)
        this.updateSubtotal()
      }
    }
  }

  // Switches free items types if they were already added to Cart
  freeItemCheck(extraItem, type = 'otp') {
    if (type == 'otp' && this.oneTimeCashier.isFree(extraItem)) {
      this.subscriptionCashier.remove(extraItem);
      this.removeCartItem(extraItem.id, 'subscription');
    } else if (type == 'subscription' && this.subscriptionCashier.isFree(extraItem)) {
      this.oneTimeCashier.remove(extraItem);
      this.removeCartItem(extraItem.id, 'otp');
    }
  }

  updateQtyWidget(id, qty, type = 'otp') {
    for (let i = 0; i < this.qtyTargets.length; i++) {
      let oid = this.qtyTargets[i].getAttribute('data-item-id')
      let otype = this.qtyTargets[i].getAttribute('data-purchase-type')
      if (oid == id && otype == type) {
        this.qtyTargets[i].value = qty;
        break
      }
    }
  }

  startProcessing() {
    this.processingTarget.classList.add('visible')
  }

  stopProcessing() {
    this.processingTarget.classList.remove('visible')
  }

  updateSubtotal() {
    let subtotal = 0
    let onlyFreeItems = this.oneTimeCashier.lineItems.filter(x => x.discount == 100).length == this.oneTimeCashier.lineItems.length
    // One time extras
    subtotal += this.oneTimeCashier.subtotal
    // Subscription extras
    subtotal += this.subscriptionCashier.subtotal
    this.subtotalTarget.innerHTML = this.currencyFormatter.format(subtotal);

    // update spice-up messages about empty cart (one time or subscription)
    if (this.oneTimeCashier.lineItems.length == 0 || !onlyFreeItems) {
      this.warnAddOneTime()
    }
    this.warnAddSubscription()
  }

  warnAddOneTime() {
    if (this.oneTimeCashier.lineItems.length == 0) {
      this.cartEmptyTarget.setAttribute("class", "cart-empty")
      this.cartEmptyMsgTarget.classList.add("cart-empty-msg")
      this.cartEmptyMsgTarget.classList.remove("filter--notMatch")
    }
    else {
      this.cartEmptyTarget.setAttribute("class", "filter--notMatch")
      this.cartEmptyMsgTarget.classList.remove("cart-empty-msg")
      this.cartEmptyMsgTarget.classList.add("filter--notMatch")
    }
  }

  warnAddSubscription() {
    if (this.hasSubscriptionEmptyTarget) {
      if (this.subscriptionCashier.lineItems.length == 0) {
        this.subscriptionEmptyTarget.classList.remove("filter--notMatch")
        this.subscriptionEmptyTarget.classList.add("cart-empty-msg")
      }
      else {
        this.subscriptionEmptyTarget.classList.add("filter--notMatch")
        this.subscriptionEmptyTarget.classList.remove("cart-empty-msg")
      }
    }
  }

  showCart(event) {
    this.cartTarget.classList.add('visible')
  }

  hideCart(event) {
    this.cartTarget.classList.remove('visible')
  }

  itemVariantChanged(event) {
    const element = event.currentTarget
    let variant = JSON.parse(element.selectedOptions[0].dataset.object)

    let cartItem = this.cartItemTargets.find(e=>e.dataset.itemId == element.dataset.itemId)

    const cashier = (cartItem.dataset.purchaseType == 'otp') ? this.oneTimeCashier : this.subscriptionCashier

    let old_item = cashier.findLineItemById(cartItem.dataset.itemId)
    cashier.include(variant, old_item.qty)
    cashier.remove(old_item)

    cartItem.dataset.itemId = variant.id
    element.dataset.itemId = variant.id

    this.updateSubtotal()
  }

  updateCartItemsCount() {
    const cart_count = this.cartCountTarget
    const count = this.oneTimeCashier.lineItemsCount + this.subscriptionCashier.lineItemsCount
    if (this.oneTimeCashier.lineItems.length > 0 || this.subscriptionCashier.lineItems.length > 0) {
      cart_count.innerHTML = count
      this.cartEmptyTarget.setAttribute("class", "filter--notMatch")
      this.cartFooterTarget.setAttribute("class", "cart-footer")
      cart_count.classList.add('cart-count')
    }
    else {
      cart_count.innerHTML = ""
      cart_count.classList.remove('cart-count')
      this.cartEmptyTarget.setAttribute("class", "cart-empty")
      this.cartFooterTarget.setAttribute("class", "filter--notMatch")
    }
    this.showCart()
  }

  getVariantTitleFromItem(item) {
    let p_title = item.product_title.replace(/\s/g, '').toLowerCase()
    let v_title = item.variant_title ? item.variant_title.replace(/\s/g, '').toLowerCase() : ''

    return p_title === v_title ? '&nbsp;' : item.variant_title
  }

  buildHTMLForExtraItem(item, type, selectOptions = null) {
    const e = document.createElement('div')
    e.classList.add("cart-item")
    e.setAttribute("data-controller", "checkout--cart-item")
    e.setAttribute('data-checkout-target', "cartItem")
    e.setAttribute('data-purchase-type', type)
    e.setAttribute('data-item-id', item.id)

    const e_image = document.createElement('div')
    e_image.classList.add("item-image")

    const image = document.createElement('img')
    image.setAttribute("data-checkout--cart-item-target", "image")
    image.setAttribute("src", `/featured_items/${encodeURIComponent(item.gid_uri)}/featured_image_src?last_image=${item.featured_image_src}`)

    const e_info = document.createElement('div')
    e_info.classList.add("item-info")
    e_info.setAttribute("data-checkout--cart-item-target", "itemInfo")

    const product_title = document.createElement('div')
    product_title.classList.add("product-title")
    product_title.setAttribute("data-checkout--cart-item-target", "productTitle")
    product_title.innerHTML = item.product_title

    const variant_title = document.createElement('div')
    variant_title.classList.add("variant-title")
    variant_title.setAttribute("data-checkout--cart-item-target", "variantTitle")
    if ( selectOptions == null) {
      variant_title.innerHTML = this.getVariantTitleFromItem(item)
      //variant_title.innerHTML = (item.product_title != item.variant_title) ? item.variant_title : ''
    }
    else {
      let clone_options = selectOptions.cloneNode(true)
      clone_options.removeAttribute("data-variant-select-item-target")
      clone_options.setAttribute("data-checkout-target", "variantOptions")
      clone_options.setAttribute("data-item-id", item.id)
      clone_options.setAttribute("data-action", "change->checkout#itemVariantChanged change->checkout--cart-item#updateContents")
      for(let i = 0; i <= clone_options.options.length; i++) {
        if (JSON.parse(clone_options.options[i].dataset.object).id == item.id) {
          clone_options.selectedIndex = i
          break
        }
      }
      variant_title.appendChild(clone_options)
    }

    const e_schedule = document.createElement('div')

    // price block
    const variant_price = document.createElement('div')
    variant_price.setAttribute("data-checkout--cart-item-target", "variantPrice")
    const variant_price_discount = document.createElement('span')

    variant_price_discount.classList.add("money", "sale")
    variant_price_discount.innerHTML = this.currencyFormatter.format(item.sale_price)
    variant_price.appendChild(variant_price_discount)

    let price = parseFloat(item.price)
    let sale_price = parseFloat(item.sale_price)
    let compared_at_price = parseFloat(item.compared_at_price)

    if (sale_price < price) {
      const variant_price_compare_at = document.createElement('span')
      variant_price_compare_at.classList.add("money", "compare-at")
      variant_price_compare_at.innerHTML = '&nbsp;' + this.currencyFormatter.format(item.price)
      variant_price.appendChild(variant_price_compare_at)
    }
    else {
      if ((compared_at_price) && (sale_price < compared_at_price)) {
        const variant_price_compare_at = document.createElement('span')
        variant_price_compare_at.classList.add("money", "compare-at")
        variant_price_compare_at.innerHTML = '&nbsp;' + this.currencyFormatter.format(item.compared_at_price)
        variant_price.appendChild(variant_price_compare_at)
      }
    }

    e_image.appendChild(image)
    e.appendChild(e_image)

    e_info.appendChild(product_title)
    e_info.appendChild(variant_title)
    e_info.appendChild(variant_price)

    const [quantity, quantity_widget, btn_minus, qty_input, btn_plus] =
      this.buildQtyButtonForExtraItem(item.id, type)

    if (item.sale_price == 0.0) {
      // Put Free Text with Quantity to the Item's Description
      const qnty_text = document.createElement('span')
      qnty_text.setAttribute("class", "heading-text")
      qnty_text.innerHTML = "&nbsp;&times;&nbsp;1"
      product_title.append(qnty_text)

      const free_item = document.createElement('span')
      free_item.classList.add("money", "sale")
      free_item.innerHTML = "Free&nbsp;"
      variant_price.prepend(free_item)

      // Disable inputs
      btn_plus.setAttribute("disabled", "disabled")
      qty_input.setAttribute("disabled", "disabled")
    }
    else {
      this.buildQtyButtonForExtraItem(item.id, type)
    }

    quantity_widget.appendChild(btn_minus)
    quantity_widget.appendChild(qty_input)
    quantity_widget.appendChild(btn_plus)

    quantity.appendChild(quantity_widget)
    e_info.appendChild(quantity)

    e.appendChild(e_info)

    if (type == 'subscription') {
      this.addSubscriptionScheduleOptions(e, item)
      e.setAttribute("data-controller", "checkout--cart-item subscription-info")
      e.setAttribute("data-subscription-info-price-value", item.price)
      e.setAttribute("data-subscription-info-sale-price-value", item.sale_price)
      variant_price.setAttribute("data-subscription-info-target", "subscriptionPrice")
    }

    /* insert on top of the list */
    if (type == 'otp') {
      this.oneTimeExtrasTarget.insertBefore(e, this.oneTimeExtrasTarget.firstChild)
      this.updateSubscribeNotification()
    } else {
      this.subscriptionExtrasTarget.insertBefore(e, this.subscriptionExtrasTarget.firstChild)
    }
  }

  addSubscriptionScheduleOptions(element, item) {
    const template = document.querySelector('#subscription_schedule_template')
    if (template) {
      const clone = template.content.cloneNode(true)
      const schedule_option = clone.querySelector("div.schedule-option")
      schedule_option.setAttribute("data-subscription-schedule-item-id", item.id)
      schedule_option.setAttribute("data-subscription-schedule-item-type", item.featured)

      //TODO: only adding the style it will wrap the schedule options
      // it should work by only adding the class.
      element.classList.add("cart-subscription-item")
      element.style["flex-wrap"] = 'wrap'

      element.appendChild(clone)
    }
  }

  buildQtyButtonForExtraItem(itemId, type) {
    const quantity = document.createElement('div')
    quantity.classList.add("quantity")

    const quantity_widget = document.createElement('div')
    quantity_widget.classList.add("quantity-widget", "small")

    const btn_minus = document.createElement('button')
    btn_minus.innerHTML = '<i class="fa fa-minus" aria-hidden="true"></i>'
    btn_minus.setAttribute("type", "button")
    btn_minus.setAttribute("data-checkout--cart-item-target", "qtyBtn")
    btn_minus.setAttribute("data-action", "click->checkout#qtyMinus")
    btn_minus.setAttribute("data-item-id", itemId)
    btn_minus.setAttribute("data-purchase-type", type)
    btn_minus.classList.add("quantity-control", "minus")

    const qty_input = document.createElement('input')
    qty_input.setAttribute("value", 1)
    qty_input.setAttribute("type", "number")
    qty_input.setAttribute("min", "0")
    qty_input.setAttribute("patern", "\d*")
    qty_input.setAttribute("data-checkout-target", "qty")
    qty_input.setAttribute("data-action", "input->checkout#qtyChange")
    qty_input.setAttribute("data-item-id", itemId)
    qty_input.setAttribute("data-purchase-type", type)
    qty_input.setAttribute("data-checkout--cart-item-target", "qty")
    qty_input.classList.add("quantity-input")

    const btn_plus = document.createElement('button')
    btn_plus.innerHTML = '<i class="fa fa-plus" aria-hidden="true"></i>'
    btn_plus.setAttribute("type", "button")
    btn_plus.setAttribute("data-checkout--cart-item-target", "qtyBtn")
    btn_plus.setAttribute("data-action", "click->checkout#qtyPlus")
    btn_plus.setAttribute("data-item-id", itemId)
    btn_plus.setAttribute("data-purchase-type", type)
    btn_plus.classList.add("quantity-control", "plus")

    return [quantity, quantity_widget, btn_minus, qty_input, btn_plus]
  }

  updateSubscribeNotification() {
    if (!this.hasNotificationTarget) return

    // Remove the last one if exists
    let oldNotification = this.oneTimeExtrasTarget.getElementsByClassName('notification-subscribe')[0]
    if (!!oldNotification) oldNotification.remove()

    // If we don't have one times anymore we return early
    if (!this.oneTimeCashier.lineItems.length) return

    // Find the first valid entry to append the alert
    let item_id = 0
    for (let i = this.oneTimeCashier.lineItems.length - 1; i >= 0; i--) {
      const el = this.oneTimeCashier.lineItems[i]
      if (el.can_subscribe) {
        if (Number(el.sale_price) > 0) {
          item_id = el.id
          break
        }
      }
    }

    // Return if not found any candidate
    if (!item_id) return

    // Clone from the reference element
    let copy = this.notificationTarget.cloneNode(true)
    copy.removeAttribute("data-checkout-target")
    copy.setAttribute("data-item-id", item_id)
    copy.classList.remove("filter--notMatch")

    // Append to the item
    for (let i = 0; i < this.cartItemTargets.length; i++) {
      const el = this.cartItemTargets[i]
      let iid = el.getAttribute('data-item-id')
      let type = el.getAttribute('data-purchase-type')
      if (iid == item_id && type == 'otp') {
        el.after(copy)
        break
      }
    }
  }

  getQtyTarget(id, type) {
    let e = this.qtyTargets.filter(x => x.getAttribute('data-item-id') == id && x.getAttribute('data-purchase-type') == type)
    return e[0]
  }

  removeCartItem(id, type) {
    for (let i = 0; i < this.cartItemTargets.length; i++) {
      let item_id = this.cartItemTargets[i].getAttribute('data-item-id')
      let purchase_type = this.cartItemTargets[i].getAttribute('data-purchase-type')
      if (id == item_id && type == purchase_type) {
        this.cartItemTargets[i].remove()
        break
      }
    }
    this.updateSubscribeNotification()
  }

  qtyPlus(event) {
    const e = event.currentTarget
    let item_id = e.getAttribute("data-item-id")
    let purchase_type = e.getAttribute("data-purchase-type")
    const qty_item = this.getQtyTarget(item_id, purchase_type);
    let cashier = purchase_type == 'subscription' ? this.subscriptionCashier : this.oneTimeCashier
    let item = cashier.findLineItemById(item_id);

    cashier.scan(item, 1)
    qty_item.value = item.qty;
    this.updateCartItemsCount()
    this.updateSubtotal()
  }

  qtyMinus(event) {
    let e = event.currentTarget
    let item_id = e.getAttribute("data-item-id")
    let purchase_type = e.getAttribute("data-purchase-type")
    const qty_item = this.getQtyTarget(item_id, purchase_type);
    const cashier = purchase_type == 'subscription' ? this.subscriptionCashier : this.oneTimeCashier
    let item = cashier.findLineItemById(item_id)

    cashier.reduce(item, 1);
    qty_item.value = item.qty;
    if (!cashier.findLineItem(item)) {
      e.disabled = true
      this.removeCartItem(item_id, purchase_type)
    }
    if (this.canAddMore()) {
      this.cartNotFull()
    }
    this.updateCartItemsCount()
    this.updateSubtotal()
  }

  qtyChange(event) {
    if (Number(event.currentTarget.value)) {
      const purchase_type = event.currentTarget.getAttribute("data-purchase-type")
      const cashier = purchase_type == 'subscription' ? this.subscriptionCashier : this.oneTimeCashier
      const itemId = event.currentTarget.getAttribute('data-item-id');
      const item = cashier.findLineItemById(itemId);
      cashier.modifyLineItemQuantity(item, Number(event.currentTarget.value));
      this.updateCartItemsCount();
      this.updateSubtotal();
    }
  }

  convertToSubscription(e) {
    e.preventDefault()
    const item_id = e.target.parentNode.parentNode.getAttribute("data-item-id")
    const item = this.oneTimeCashier.findLineItemById(item_id)
    const select_options = this.itemVariantOptions(item_id)
    this.oneTimeCashier.remove(item);
    this.addToCart(item, 'subscription', select_options)
    this.removeCartItem(item.id, 'otp')
  }

  itemVariantOptions(item) {
    for(let i = 0 ; i < this.variantOptionsTargets.length; i++) {
      if(this.variantOptionsTargets[i].dataset.itemId == item) {
        return this.variantOptionsTargets[i]
      }
    }
  }

  variantOptions(node) {
    let nodes = node.childNodes
    let select_options = null
    for(let i=0; i < nodes.length; i++) {
      if(nodes[i].classList && nodes[i].classList.contains('product-info-options')) {
        select_options = nodes[i].firstChild
        break
      }
    }
    return select_options
  }
}
