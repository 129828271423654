import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'adminSidebarContainer', 'adminSidebar' ]
  
  openSidebar(event) {
    this.adminSidebarContainerTarget.classList.add('active')
  }

  hideSidebar(event) {
    if (this.adminSidebarContainerTarget.classList.contains('active') && !this.adminSidebarTarget.contains(event.target)) {
      this.adminSidebarContainerTarget.classList.remove('active')
    }
  }

}
