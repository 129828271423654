import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'verified' ]
  static values = { url: String }

  verify(event) {
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    let button = event.currentTarget

    button.disabled = true

    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
    .then(response => response.json())
    .then(text => {
      if (text == true) {
        this.verifiedTarget.style.display = "block"
        button.style.display = "none"
      } else {
        button.disabled = false
      }
    })

  }

}
