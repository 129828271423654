import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["entries", "pagination", "feedback"]

  initialize() {
    this.requested_pages = []

    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(
      entries => this.processIntersectionEntries(entries), 
      options
    )
    this.paginationTarget.style.opacity = "0"
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && this.entriesTarget.offsetHeight > 150) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { 
      this.paginationTarget.style.display = "none"
      return 
    }

    let url = next_page.href

    /* Since loadMore is triggered by scroll event this will */
    /* prevent multiple requests for the same url */
    if (!this.requested_pages.includes(url)) {

      this.requested_pages.push(url)
      this.toggleFetchingClass()

      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
          this.paginationTarget.innerHTML = data.pagination
        },
        complete: () => {
          this.toggleFetchingClass(false)
        }
      })
    }
  }

  toggleFetchingClass(enable=true) {
    this.hasFeedbackTarget && this.feedbackTarget.classList.toggle('fetching', enable)
  }
}
