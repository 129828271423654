import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    data: Array,
    name: String
  }

  static targets = ["template"]

  connect() {
    this.element[this.identifier] = this
  }

  updateData() {
    const table = this.tableEl
    const template = this.templateTarget
    const tbody = table.querySelector('tbody')

    this.clearTable(tbody)

    const dataKeys = Object.keys(this.dataValue[0])
    this.dataValue.forEach((row, i) => {
      const clone = template.content.cloneNode(true)
      let td = clone.querySelectorAll('td')
      dataKeys.forEach((dataKey, i) => {
        td[i].textContent = row[dataKey]
      })
      tbody.appendChild(clone)
    })
  }

  clearTable(node) {
    var child = node.lastElementChild
    while (child) {
      node.removeChild(child)
      child = node.lastElementChild
    }
  }

  dataToCsvFormat() {
    const csvRows = [];
    csvRows.push(this.dataKeys.join(','));
    for (const row of this.dataValue) {
      const values = this.dataKeys.map(key => {
        if(/,/.test(row[key])) return `"${row[key]}"`;

        return row[key]; 
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  toCsv() {
    const CSVFile = new Blob([this.dataToCsvFormat()], { type: "text/csv" });

    let temp_link = document.createElement('a');

    temp_link.download = `${this.csvFilename}.csv`;
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
  }

  get tableEl() {
    return this.element.querySelector('table')
  }

  get dataKeys() {
    return Object.keys(this.dataValue[0])
  }

  get csvFilename() {
    if(this.hasNameValue) return this.nameValue;

    return 'table'
  }
} 
