import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectOptions" ]
  static values = {
    url: String
  }

  connect () {
    this.feature_item_id = this.data.get("item-id")
    this.feature_item_type = this.data.get("item-type")
    if(this.feature_item_id=="null") {
      this.noOptionsAvailable("not available while previewing edition")
    }
    else {
      this.fetchOptions()
    }
  }

  subscriptionPlansUrl() {
    return this.urlValue + '?featured_id=' + this.feature_item_id + '&featured=' + this.feature_item_type
  }

  fetchOptions() {
    const select = this.selectOptionsTarget
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    fetch(this.subscriptionPlansUrl() ,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          this.populateOptions(data)
        })
      }
    })
  }

  populateOptions(data) {
    if (data.subscription_preferences.length < 1) {
      this.noOptionsAvailable("No options available for this item") 
    }
    else {
      data.subscription_preferences.forEach((value) => {
        if(value.type=="subscription") {
          var opt = document.createElement("option")
          value.subscription_preferences['item_id'] = this.feature_item_id
          value.subscription_preferences['item_type'] = this.feature_item_type
          value.subscription_preferences['discount_amount'] = value.discount_amount
          value.subscription_preferences['discount_type'] = value.discount_type
          opt.value = JSON.stringify(value.subscription_preferences)
          opt.text = value.human_interval_frequency
          this.selectOptionsTarget.add(opt)
        }
      })
      this.selectOptionsTarget.remove(0)
      this.selectOptionsTarget.dispatchEvent(new Event('change'))
    }
  }

  noOptionsAvailable(message) {
    var opt = document.createElement("option")
    opt.text = message
    this.selectOptionsTarget.add(opt)
    this.selectOptionsTarget.remove(0)
  }

  decorateInterval(number, unit) {
    return number + ' ' + unit + ((number > 1)? 's' : '')
  }
}
