import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "rangeValue" ]

  update(event) {
    const el = event.currentTarget
    this.rangeValueTarget.innerHTML = `${el.value} px` 
  }

}
