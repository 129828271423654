import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    active: Boolean,
    target: String,
    template: String
  }

  connect() {
    if (this.activeValue) {
      this.showTab()
    }
  }

  showTab() {
    const container = document.getElementById(this.targetValue)
    const template = document.getElementById(this.templateValue)
    const clone = template.content.cloneNode(true)
    container.replaceChildren(clone)
  }
}
