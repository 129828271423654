import './flatpickr.scss';
import Flatpickr from 'stimulus-flatpickr';
import { Flatpickr_Locales } from '../../javascript/packs/locales';

export class Controller extends Flatpickr {
  static values = { date: String, locale: String }

  initialize() {
    this.config = {
      dateformat: "U",
      inline: true,
      locale: this.locale,
      position: "auto center"
    }
  }

  change(selectedDates, dateStr, instance) {
    this.addMonthEndSelector();
    this.addMonthStartSelector();
    this.monthChangeButtonStatus(selectedDates, instance);
    this.dateValue = dateStr;
  }

  monthChange(selectedDates, dateStr, instance) { 
    this.addMonthEndSelector();
    this.addMonthStartSelector();
    this.monthChangeButtonStatus(selectedDates, instance);
  }

  // Insert .monthEnd class to last child
  addMonthEndSelector() {
    let daysBoxNodes = document.querySelectorAll('.dayContainer');
    Array.from(daysBoxNodes).forEach((boxNode) => {
      let endReached = boxNode.querySelector('.endRange:not(.hidden)');
      if(endReached) return

      let daysNodes = boxNode.querySelectorAll('.inRange');
      let lastInRange = Array.from(daysNodes).reverse().find((node) => {
        return node.classList.contains('inRange') && !node.classList.contains('hidden')
      })
      if(lastInRange) lastInRange.classList.add('monthEnd')
    })
  }

  addMonthStartSelector() {
    let daysBoxNodes = document.querySelectorAll('.dayContainer');
    Array.from(daysBoxNodes).forEach((boxNode) => {
      let startReached = boxNode.querySelector('.startRange:not(.hidden)');
      if(startReached) return

      let startInRange = boxNode.querySelector('.inRange:not(.hidden)');
      if(startInRange) startInRange.classList.add('monthStart')
    })
  }

  monthChangeButtonStatus(selectedDates, instance) {
    const [startDate, endDate] = this.startAndEndDates(selectedDates)
    if(!startDate || !endDate) return
    const offset = this.element.dataset.datepickerShowMonths - 1 
    const beforeStart = new Date(instance.currentYear, instance.currentMonth + offset, startDate.getUTCDate()) < startDate
    const afterEnd = new Date(instance.currentYear, instance.currentMonth, 1) > endDate
    const nextMonthBtn = instance.nextMonthNav
    const prevMonthBtn = instance.prevMonthNav

    // reset
    prevMonthBtn.classList.remove('active')
    nextMonthBtn.classList.remove('active')

    if(beforeStart) {
      nextMonthBtn.classList.add('active')
    } 
    if(afterEnd) {
      prevMonthBtn.classList.add('active')
    }
  }

  startAndEndDates(selectedDates) {
    // Single Mode
    if(selectedDates.length == 1) {
      return Array(2).fill(new Date(selectedDates[0]));
    }

    // Range Mode
    if(selectedDates.length == 2) {
      return selectedDates.map((date) => new Date(date));
    }

    return Array(2)
  }

  get locale() {
    if (this.hasLocaleValue) {
      return Flatpickr_Locales[this.localeValue]
    } else {
      return ""
    }
  }
}
