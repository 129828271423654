import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { url: String , campaignClassName: String }
  static targets = [
    'status',
    'sendBtn',
    'gotItBtn',
    'emails',
    'successContent',
    'formContent',
    'emailList',
    'failContent'
  ]

  toggleDisplay(element) {
    let display = element.style.display
    element.style.display = (display == 'none')? 'block':'none'
  }

  toggleFormModal(){
    const formContent = this.formContentTarget
    this.toggleDisplay(formContent)
  }

  closeFailModal(){
    const failContent = this.failContentTarget
    this.toggleDisplay(failContent)
    this.toggleFormModal()
  }

  closeSuccessModal(){
    const successContent = this.successContentTarget
    this.toggleDisplay(successContent)
    this.toggleFormModal()
  }

  closeModal(){
    const list = this.emailListTarget
    const failContent = this.failContentTarget
    const form = this.formContentTarget
    const success = this.successContentTarget
    form.style.display = 'none'
    success.style.display = 'none'
    failContent.style.display = 'none'
    list.innerHTML = ""
  }

  buildSuccessList(data) {
    const successContent = this.successContentTarget
    const formContent = this.formContentTarget
    const list = this.emailListTarget
    let emails = data['sent_emails']
    if(emails.length==0){
      const failContent = this.failContentTarget
      this.toggleDisplay(formContent)
      this.toggleDisplay(failContent)
    }
    else {
      for(let i=0 ; i < emails.length ; i++){
        const li = document.createElement('li')
        li.innerHTML = emails[i]
        list.appendChild(li)
      }
      this.toggleDisplay(formContent)
      this.toggleDisplay(successContent)
    }
  }

  sendPreview(event){
    const emails = this.emailsTarget
    if (emails.value.length == 0) { return }

    let token = document.head.querySelector("[name~=csrf-token][content]").content

    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ emails: emails.value, type: this.campaignClassNameValue }) 
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('No messages sent.')
      }
      return response.json()
    })
    .then(json => {
      this.buildSuccessList(json)
    })
    .catch(error => {
      console.error('There has been a problem with your request:', error)
    })
  }
}
