import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "shortDescription" ]

  update(event) {
    const text = event.currentTarget.value
    console.log('description', text)
    this.shortDescriptionTarget.value = text
  }
}
