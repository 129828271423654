import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

class EmailDeliveryController extends Controller {
  static targets = ['dkimTitleBox', 'returnPathTitleBox', 'errorNotification', 'badge']
  static values = { urlVerifyDomain: String, urlCreateSignature: String, urlVerifySignature: String }

  connection() { }
  
  verify(event) {
    const self = this
    const target = event.currentTarget
    target.setAttribute('disabled', true)
    Rails.ajax({
      url: this.urlVerifyDomainValue,
      type: 'PATCH',
      success: function(response) {
        self.updateDnsSettingsStatus(response)
        self.updateDomainBadge(response)
        self.toggleNotification(response)
      },
      complete: () => {
        target.removeAttribute('disabled')
      }
    })
  }

  createSignature(event) {
    const data = event.currentTarget.dataset
    const params = { email: data.email, name: data.name }
    Rails.ajax({
      url: this.urlCreateSignatureValue,
      type: 'POST',
      data: new URLSearchParams(params).toString()
    })
  }

  verifySenderSignature(event) {
    const self = this
    const target = event.currentTarget
    const data = target.dataset
    const params = { email: data.email, name: data.name }
    const modal = target.closest('[data-controller=modal]').modal
    target.setAttribute('disabled', true)
    Rails.ajax({
      url: this.urlVerifySignatureValue,
      type: 'GET',
      data: new URLSearchParams(params).toString(),
      success: function (response) {
        self.updateSignature(target, response.confirmed)
      },
      complete: () => {
        target.removeAttribute('disabled')
        modal.hide()
      }
    })
  }

  updateSignature(target, confirmed) {
    const icon = target.closest('li.email-listing').querySelector('i') 
    if(confirmed) {
      icon.className = 'fas fa-check success-text'
    } else {
      icon.className = 'fas fa-question grey-text'
    }
  }

  updateDnsSettingsStatus(data) { 
    this.dnsSettingsTargets(data).forEach((verification) => {
      const icon = verification.target.querySelector('i')
      const titles = verification.target.querySelectorAll('h5')
      const statusText = titles[titles.length-1]

      icon.className = this.status[verification.status]['icon']
      icon.classList.add('inline-icon')
      statusText.className = this.status[verification.status]['text']
      statusText.innerHTML = this.status[verification.status]['name']
    })
  }
  
  toggleNotification(data) {
    if(data.return_path_domain_verified && data.dkim_verified) {
      this.errorNotificationTarget.style.display = 'none'
    } else {
      this.errorNotificationTarget.style.display = 'block'
    }
  }

  dnsSettingsTargets(data) {
    const return_path_status = data.return_path_domain_verified ? 'Verified' : 'Not Verified'
    const dkim_status = data.dkim_verified ? 'Verified' : 'Not Verified'
    return [
      { target: this.dkimTitleBoxTarget, status: dkim_status },
      { target: this.returnPathTitleBoxTarget, status: return_path_status }
    ]
  }

  updateDomainBadge(data) {
    const verified = data.return_path_domain_verified && data.dkim_verified
    if(verified) {
      this.badgeTarget.className = 'badge badge-success'
      this.badgeTarget.innerHTML = 'Verified'
    }
  }

  status = {
    'Verified': {
      icon: 'fas fa-check-circle verified',
      text: 'success-text',
      name: 'Verified'
    },
    'Not Verified': {
      icon: 'fas fa-times-circle not-verified',
      text: 'error-text',
      name: 'Not Verified'
    }
  }
}

export default EmailDeliveryController
