import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ 
    'previewIframe',
    'selectPreview', 
    'editSection', 
    'toolbarBtn', 
    'sendPreviewBtn',
    'sendPreviewModal'
  ]

  loadPreview(event) {
    this.previewIframeTarget.src = this.getPreviewUrl(event.currentTarget)
  }

  getPreviewUrl(element) { 
    const select = element
    const option = select.options[select.selectedIndex]
    return option.dataset.url
  }

  newWindowPreview(event) {
    window.open(this.previewIframeTarget.src, '_blank')
  }

  toggleWideView(event) {
    this.editSectionTarget.classList.toggle('hide-left')
  }

  mobileView(event) {
    this.btnsOff()
    this.previewIframeTarget.classList.remove('desktop-view')
    this.previewIframeTarget.classList.add('mobile-view')
    const btn = event.currentTarget
    btn.classList.add('active')
  }

  desktopView(event) {
    this.btnsOff()
    this.previewIframeTarget.classList.add('desktop-view')
    this.previewIframeTarget.classList.remove('mobile-view')
    const btn = event.currentTarget
    btn.classList.add('active')
  }

  btnsOff(){
    this.toolbarBtnTargets.forEach(btn => btn.classList.remove('active'))
  }

  toggleElementDisplay(element) {
    let display = element.style.display
    element.style.display = (display == 'none')? 'block':'none'
  }

  toggleSendPreviewBtn() {
    const btnDiv = this.sendPreviewBtnTarget
    this.toggleElementDisplay(btnDiv)
  }

  toggleSendPreviewModal(){
    const modalDiv = this.sendPreviewModalTarget
    this.toggleElementDisplay(modalDiv)
  }

  navigateTo(event) {
    const previewPageName = event.currentTarget.dataset.navigationPage
    const option = Array.from(this.selectPreviewTarget.children).find((option) => option.value == previewPageName)
    const iframe = document.querySelector('iframe')
    if(option && iframe.src != option.dataset.url) {
      iframe.src = option.dataset.url
      option.selected = true
    }
  }
}

