import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "giftOptions", "giftOptionsLabel", "tab", "giftOptionsInstruction", "giftOptions" ]

  formIsChanged(event) {
    this.setChanged("true")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  toggleOptions(event) {
    this.setChanged("true")
    if (event.currentTarget.checked) {
      this.giftOptionsLabelTarget.innerHTML = "Gifts Enabled"
      this.giftOptionsTarget.classList.remove('filter--notMatch')
      this.giftOptionsInstructionTarget.classList.remove('filter--notMatch')
    }
    else {
      this.giftOptionsLabelTarget.innerHTML = "Gifts Disabled"
      this.giftOptionsTarget.classList.add('filter--notMatch')
      this.giftOptionsInstructionTarget.classList.add('filter--notMatch')
    }
    document.getElementById('gift_setting_enabled').value = event.currentTarget.checked
    this.toggleNode(this.giftOptionsTarget, event.currentTarget.checked)
  }

  toggleNode(target, value) {
    if (value) {
      target.classList.remove('filter--notMatch')
    } else {
      target.classList.add('filter--notMatch')
    }
  }
}
