import { Controller } from 'stimulus'
import Rails from "@rails/ujs"
import FlashUtils from '../packs/flash_utils'

class VariantsSwapController extends Controller {
  static targets = ['previousVariantTitle', 'listedPreviousVariant', 'processing', 'form']
  static values = {
    urlSwapConfirmedPage: String,
    urlSwapVariant: String,
    orderId: Number,
    productId: Number,
    selectedIdVariant: Number,
    selectedVariant: Object,
    prices: Object
  }

  performSwap(event) {
    event.preventDefault()
    const form = document.getElementById("swaps-form")
    let urlToRedirect = this.urlSwapConfirmedPageValue
    let swapId, price, swapOption
    if(form) {
      swapId = form.elements['swap_variant_id'].value
      price = this.pricesValue[swapId]
      swapOption = form.elements['swap_option'].value
    } else {
      swapId = this.selectedVariantIdValue
      price = this.selectedVariantValue.price
    }
    
    let endpoint = this.addParamToURl(this.urlSwapVariantValue, 'swap_variant_id', swapId)  
    endpoint = this.addParamToURl(endpoint, 'swap_variant_price', price)
    endpoint = this.addParamToURl(endpoint, 'swap_option', swapOption)
    this.startProcessing()
    if(endpoint.href) {
      Rails.ajax({
        url: endpoint.href,
        type: 'PATCH',
        dataType: 'json',
        accept: 'json',
        success: () => {
          location.href = this.urlSwapConfirmedPageValue;
        },
        error: (errResponse) => {
          const parentElement = document.querySelector('.variants-swap-header')
          const errorNotificationEl = new FlashUtils(errResponse.errors, 'error').buildStaticNotification()
          parentElement.prepend(errorNotificationEl)
          this.modalEl.modal.hide()
          this.stopProcessing()
        }
      })
    } else {
      urlToRedirect = this.addParamToURl(urlToRedirect, 'swap_variant_id', swapId) 
      urlToRedirect = this.addParamToURl(urlToRedirect, 'swap_option', swapOption) 
      location.href = urlToRedirect.href;
    }
  }

  startProcessing() {
    this.processingTarget.classList.add('visible')
  }

  stopProcessing() {
    this.processingTarget.classList.remove('visible')
  }

  get modalEl() {
    return this.element.querySelector('[data-controller=modal]')
  }

  get modalSelectedEl() {
    return this.modelSelectedElement
  }

  set modalSelectedEl(id) {
    this.modelSelectedElement = this.element.querySelector(`#modal-variant-item-${id}`)
  }

  addParamToURl(url, key, value) {
    if(url) {
      const urlObj = new URL(url)
      const paramsUrl = new URLSearchParams(urlObj.search.substr(1))
      if(value) paramsUrl.set(key, value)
      urlObj.search = paramsUrl
      return urlObj
    } else {
      return url
    }
  }
}

export default VariantsSwapController
