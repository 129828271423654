export default class PreviewUtils {
  constructor(iframeTarget=null, formTarget=null,  token=null) {
    this.formTarget = formTarget
    this.iframeTarget = iframeTarget
    this.token = token
  }

  get form() {
    if(this.formTarget) return this.formTarget
    return document.forms[0]
  }

  get iframe() {
    if(this.iframeTarget) return this.iframeTarget
    return document.querySelector('iframe')
  }

  formParams() {
    const formData = new FormData(this.form)
    formData.delete('authenticity_token')
    formData.delete('_method')
    return formData
  }

  reloadWithFormParams() {
    if (this.token == null)
      this.token = document.head.querySelector("[name~=csrf-token][content]").content
    fetch(this.iframe.src, {
      method: 'PATCH',
      headers: { 'X-CSRF-Token': this.token },
      body: this.formParams()
    })
      .then((resp) => {
        return resp.text()
      })
      .then((html) => {
        const styles = document.querySelectorAll('style');
        this.iframe.contentDocument.open()
        let styles_string = Array.from(styles).map((style) => "<style>" + style.innerHTML + "</style>").join('')
        this.iframe.contentDocument.write(html != null ? html + styles_string : '')

        this.iframe.contentDocument.close()
      })
  }
}

