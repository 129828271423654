import { Controller } from "stimulus"
import { arpu_currency_formatting } from "../packs/arpu_utils.js"

export default class extends Controller {

  static targets = ["selection", "noSelection", "selectionContainer","add_item", "template", "new", "persisted"]

/* 
  formNamespace is required and it should reflect the
  suffix used in the form_builder for this specific object
  association with featured_items.  */
  static values = { formNamespace: String }

  selectItem(event) {
    this.addSelectItem(event.currentTarget)
  }

  addSelectItem(element) {
    let current_id = element.dataset.gidUri

    if (this.selectionContainerTarget.innerHTML.includes(`value="${current_id}"`)) {
      /* gid already present */
      return
    }

    /* hide no selection box and show the product grid */
    this.noSelectionTarget.classList.add('filter--notMatch')
    this.selectionContainerTarget.classList.remove('filter--notMatch')
 
    /* build product association */
    let index = new Date().valueOf()
    this.add_association(element,index)

    /* Add product on the selection grid */
    const image_tag = document.createElement("img")
    image_tag.setAttribute("data-featured-item-target", "featuredImage")
    image_tag.setAttribute("data-price-discount-target", "featuredImage")
    image_tag.setAttribute("src", element.dataset.featuredImageSrc)

    document.getElementById(index+"_featured_image").appendChild(image_tag)
    document.getElementById(index+"_product_title").innerHTML = element.dataset.productTitle
    document.getElementById(index + "_variant_title").innerHTML = element.dataset.variantTitle

    /* Insert pricing */
    if (element.dataset.comparedAtPrice === undefined) {
      document.getElementById(index + "_price").innerHTML = this.moneyFormat(element.dataset.price)
      document.getElementById(index + "_compared_at_price").classList.add('filter--notMatch')
    } else {
      if (element.dataset.price == element.dataset.comparedAtPrice) {
        document.getElementById(index + "_compared_at_price").classList.add('filter--notMatch')
      }
      document.getElementById(index + "_price").innerHTML = this.moneyFormat(element.dataset.price)
      document.getElementById(index + "_compared_at_price").innerHTML = this.moneyFormat(element.dataset.comparedAtPrice)
    }

    document.getElementById(index + "_price").classList.add('sale')

    /* Variants info */
    if (!!element.dataset.variants) {
      let variants = JSON.parse(element.dataset.variants)
      const select_tag = document.createElement("select")
      select_tag.setAttribute("data-action", "change->price-discount#applyVariantOption")

      for (let o = 0; o < variants.length; o++) {
        const option = document.createElement("option")

        let variant_option = JSON.parse(variants[o])
        variant_option.sale_price = variant_option.price
        variant_option.original_price = variant_option.compared_at_price

        option.setAttribute("data-variant", JSON.stringify(variant_option))
        option.setAttribute("data-object", variants[o])

        option.innerHTML = variant_option.variant_title
        select_tag.appendChild(option)
      }
      document.getElementById(index + "_variant_title").appendChild(select_tag)
    } else {
      document.getElementById(index + "_variant_title").innerHTML = element.dataset.variantTitle
    }
  }

  removeItem(event){  
    let item_index = event.currentTarget.dataset.itemIndex
    let node = event.currentTarget.parentNode

    /* products already saved in the campaign has a persisted value 
     then we only set destroy = true and hide the DOM */
    if(node.dataset.giftOptionsSelectTarget == 'persisted') {
      document.getElementById(this.formNamespaceValue+item_index+"__destroy").value = true
      node.setAttribute("class", "filter--notMatch")
    }
    else {
    /* products not persisted yet, we just remove the DOM */ 
      node.remove()
    }
  }

  add_association(element,id) {
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, id)
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)

    this.getFormField(id, "_gid_uri").value = element.dataset.gidUri
    this.getFormField(id, "_featured_image_src").value = element.dataset.featuredImageSrc
    this.getFormField(id, "_title").value = element.dataset.productTitle
    if (!!element.dataset.variantTitle) {
      this.getFormField(id, "_variant_title").value = element.dataset.variantTitle      
    }
    this.getFormField(id, "_price").value = element.dataset.price
    this.getFormField(id, "_compared_at_price").value = element.dataset.comparedAtPrice
  }

  getFormField(id,suffix) {
    return document.getElementById(this.formNamespaceValue + id + suffix)
  }

  moneyFormat(number) {
    let format = this.moneyFormattingJsNumberFormatValue
    let prefix = this.moneyFormattingPrefixValue
    let suffix = this.moneyFormattingSuffixValue
    let currency_code = this.currencyValue
    return arpu_currency_formatting(number, prefix, format, suffix, currency_code)
  }
}
