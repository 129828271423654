import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["widget", "statusLabel", "toggleLink", "modal", "enableModal", "disableModal", "cardEnabledArpu", "cardDisabledArpu", "container"]
  static values = { enabled: Boolean , url: String }

  openModal() {
    this.modalTarget.style.display = "block"
  }
 
  closeModal() {
    this.modalTarget.style.display = "none"
  }
 
  toggle(){
    this.closeModal()
    this.async_toggle()
  }

  async_toggle(){
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
       },
    })
    .then(response => response.ok)
    .then(ok => {
       if( this.enabledValue == false) this.enable()
       else this.disable() 
    })
  }

  enable() {
    this.widgetTarget.classList.remove('sidebar-store-status--disabled');
    this.widgetTarget.classList.add('sidebar-store-status--enabled');
    this.toggleLinkTarget.innerHTML = "Change..."
    this.statusLabelTarget.innerHTML = "Enabled"
    this.enableModalTarget.style.display = "none"
    this.disableModalTarget.style.display = "block"
    this.enabledValue = true
    this.cardDisabledArpuTarget.style.display = 'none';
    this.cardEnabledArpuTarget.style.display = 'flex';
  }

  disable() {
    this.widgetTarget.classList.remove('sidebar-store-status--enabled');
    this.widgetTarget.classList.add('sidebar-store-status--disabled');
    this.toggleLinkTarget.innerHTML = "Enable..."
    this.statusLabelTarget.innerHTML = "Disabled"
    this.enableModalTarget.style.display = "block"
    this.disableModalTarget.style.display = "none"
    this.enabledValue = false
    this.cardDisabledArpuTarget.style.display = 'flex';
    this.cardEnabledArpuTarget.style.display = 'none';
   }
   
  toggleContainer(event) {
    if(event.currentTarget.checked) {
      this.containerTarget.classList.remove('filter--notMatch')
    } else {
      this.containerTarget.classList.add('filter--notMatch')
    }
  }
}
