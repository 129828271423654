import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'storeSwitcherModal', 'changeBtn' ]
  static values = { }

  openModal(){
    this.storeSwitcherModalTarget.style.display = "block"
  }

  closeModal(){
    this.storeSwitcherModalTarget.style.display = "none"
  }

  selectStore(event){
    const option = event.currentTarget
    const icon = option.firstElementChild
    this.toggleIcons()
    icon.classList.replace("far","fas")
    icon.classList.replace("fa-circle","fa-check-circle")
    icon.classList.replace("store-switcher","store-switcher-active")
    this.changeBtnTarget.dataset.url = option.dataset.store
  }

  toggleIcons(){
    let icons =  document.getElementsByClassName("store-switcher-active")
    for(let i = 0 ; i < icons.length; i++) {
      icons[i].classList.replace("fas","far")
      icons[i].classList.replace("fa-check-circle","fa-circle")
      icons[i].classList.replace("store-switcher-active","store-switcher")
    }
  }

  changeStore(event){
    const btn = event.currentTarget
    window.location = btn.dataset.url
  }

}
