// Translations available here: https://github.com/flatpickr/flatpickr/blob/master/src/l10n/
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import { Italian } from "flatpickr/dist/l10n/it.js";
import { english } from "flatpickr/dist/l10n/default.js";
import { Danish } from "flatpickr/dist/l10n/da.js";
import { German } from "flatpickr/dist/l10n/de.js";

export const Flatpickr_Locales = {
  fr: French,
  en: english,
  nl: Dutch,
  it: Italian,
  da: Danish,
  de: German
};

// Using FlatPickrLocale as default
export const Strftime_Locales = (language) => {
  const flatpickrLocale = Flatpickr_Locales[language]
  return {
    days:        flatpickrLocale.weekdays.longhand,
    shortDays:   flatpickrLocale.weekdays.shorthand,
    months:      flatpickrLocale.months.longhand,
    shortMonths: flatpickrLocale.months.shorthand,
    AM:          'AM',
    PM:          'PM',
    am:          'am',
    pm:          'pm'
  }
}