import { Controller } from "stimulus"

export default class extends Controller {
  static values = { url: String, wrapperHtml: String, loadingMsg: String, errorMsg: String, tryAgainMsg: String, }

  connect() {
    if (!this.loadingMsgValue) this.loadingMsgValue = "Loading..."
    if (!this.errorMsgValue) this.errorMsgValue = "Error"
    if (!this.tryAgainMsgValue) this.tryAgainMsgValue = "Try again"

    this.load()
  }
  
  load() {
    this.element.innerHTML = this.wrappedMsg(this.loadingMsgValue)
    fetch(this.urlValue)
      .then(response => response.ok ? response.text() : this.wrappedMsg(this.errorMsgValue) )
      .then(html => this.element.innerHTML = html)
  }

  reload(e) {
    e.preventDefault();
    this.load();
  }

  wrappedMsg(msg) {
    var message = msg
    message = message.replace('{{reload}}', '<a href="#" data-action="click->contentLoader#reload">' + this.tryAgainMsgValue + '</a>')
    if (this.wrapperHtmlValue) {
      message = this.wrapperHtmlValue.replace('{{message}}', message)
    }
    return message
  }
}