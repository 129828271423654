import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "password", "confirmPassword", "context", "payload", "acceptTerms", "continue", "loaded", "form", "error" ]
  static values = { url: String }

  acceptTerms(event){
    this.continueTarget.disabled = ! event.target.value
  }

  matchingPassword() {
    if (this.passwordTarget.value.length > 3) { 
      return (this.confirmPasswordTarget.value == this.passwordTarget)
    }
    return false
  }

  setPassword(event){
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    let formData = new FormData()
    let data = { 
       password: this.passwordTarget.value,
       confirm_password: this.confirmPasswordTarget.value,
       signed_payload: this.payloadTarget.value,
       context: this.contextTarget.value
    }
    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(data) 
    })
    .then(response => response.ok)
    .then(ok => {
      const load_div = this.loadedTarget
      const form_div = this.formTarget
      load_div.style.display = "block"
      form_div.style.display = "none"
    }) 
    .catch((error) => {
       this.showError()
    })
  }

  showError(){
    this.loadedTarget.style.display = "none"
    this.errorTarget.style.display = "block"
  }

}
