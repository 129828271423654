export const debounceLeading = (fn, delay = 300) => {
  let timer;
  return (...args) => {
    if (!timer) {
      fn.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, delay)
  }
}

export const debounce = (fn, delay = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
      timer = setTimeout(() => { fn.apply(this, args); }, delay);
  };
}

export const throttle = (fn, delay = 300) => {
  let waiting = false;                      
  return (...args) => {                      
    if (!waiting) {                       
      fn.apply(this, args);  
      waiting = true;                   
      setTimeout(function () {          
        waiting = false;              
      }, delay);
    }
  }
}