import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['successResponse', 'errorResponse']
  static values = { defaultMessage: String, defaultErrorMessage: String }

  createSuccess(event) {
    if (this.hasDefaultMessageValue) {
      this.successResponseTarget.innerHTML = this.defaultMessageValue;
    } else {
      const [_data, status, xhr] = event.detail
      this.successResponseTarget.innerHTML = xhr.response;
    }
  }

  createError(event) {
    if (this.hasDefaultErrorMessageValue) {
      this.errorResponseTarget.innerHTML = this.defaultErrorMessageValue;
    } else {
      const [_data, status, xhr] = event.detail
      this.errorResponseTarget.innerHTML = xhr.response.errors;
    }
  }
}