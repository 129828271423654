import { Controller } from "stimulus"
import { CurrencyFormatter } from "../../packs/checkout"

export default class extends Controller {

  static targets = [ "image", "variantPrice", "qtyBtn", "qty" ]
 
  initialize() {
    const cart = document.getElementById('checkout').dataset
    this.currencyFormatter = new CurrencyFormatter(
      cart.checkoutMoneyFormattingPrefixValue,
      cart.checkoutMoneyFormattingJsNumberFormatValue,
      cart.checkoutMoneyFormattingSuffixValue,
      cart.checkoutCurrencyValue
    )
  }

  updateContents(event) {
    const element = event.currentTarget
    let variant = JSON.parse(element.selectedOptions[0].dataset.object)
    this.updateImage(variant)
    this.updateQtyComponent(variant)
    this.updatePriceInfo(variant)
  }

  updatePriceInfo(item) {
    const node = this.variantPriceTarget
    this.purgeChildren(node)
    this.addPriceInfo(node, item)
  }

  purgeChildren(node) {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
  }

  addPriceInfo(node, item) {
    const variant_price_discount = document.createElement('span')

    variant_price_discount.classList.add("money", "sale")
    variant_price_discount.innerHTML = this.currencyFormatter.format(item.sale_price)
    node.appendChild(variant_price_discount)

    let price = parseFloat(item.price)
    let sale_price = parseFloat(item.sale_price)
    let compared_at_price = parseFloat(item.compared_at_price)

    if (sale_price < price) {
      const variant_price_compare_at = document.createElement('span')
      variant_price_compare_at.classList.add("money", "compare-at")
      variant_price_compare_at.innerHTML = '&nbsp;' + this.currencyFormatter.format(item.price)
      node.appendChild(variant_price_compare_at)
    }
    else {
      if ((compared_at_price) && (sale_price < compared_at_price)) {
        const variant_price_compare_at = document.createElement('span')
        variant_price_compare_at.classList.add("money", "compare-at")
        variant_price_compare_at.innerHTML = '&nbsp;' + this.currencyFormatter.format(item.compared_at_price)
        node.appendChild(variant_price_compare_at)
      }
    }
  }

  updateQtyComponent(item) {
    let id = item.id
    this.qtyTarget.dataset.itemId = id
    this.qtyBtnTargets[0].dataset.itemId = id
    this.qtyBtnTargets[1].dataset.itemId = id
  }

  updateImage(item) {
    this.imageTarget.src = `/featured_items/${encodeURIComponent(item.gid_uri)}/featured_image_src?last_image=${item.featured_image_src}`
  }
}
