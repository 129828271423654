import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'wrapper', 'calendarWidget', 'inputField', 'dateWrapper', 'backdrop', 'spinningIcon', 'filterButton' ]
  static values = { url: String }

  connect() {
    this.interval = null
    this.flatpickrInstance = null

    // We have to wait a little as the flatpickr controller is mounted after ours
    setTimeout(() => {
      this.flatpickrInstance = this.calendarWidgetTarget._flatpickr
      this.flatpickrInstance.config.onChange.push(this.calendarHandler)
    }, 500);
  }

  toggleMenu() {
    this.backdropTarget.classList.toggle('show')
    this.dateWrapperTarget.classList.toggle('hide')
  }

  selectPrefilter(event) {
    // Set the active state
    this.removeActiveOption()
    event.currentTarget.classList.add('active')
    // Enables the search btn
    this.filterButtonTarget.disabled = false
    
    // Get current target interval
    const option = event.currentTarget.dataset.analyticsRangeTarget
    this.interval = option
    this.updateRangeValue(event.currentTarget.innerText)

    // Clear previously selected range
    this.flatpickrInstance.clear()
  }

  updateRangeValue(text) {
    text = text || this.interval
    this.inputFieldTarget.value = text
  }

  removeActiveOption() {
    // Clear preselected option active state
    const active = document.querySelector('.btn-link.active');
    if(active) {
      active.classList.remove('active');
    }
    this.interval = null
  }

  filter() {
    this.toggleMenu()
    this.startProcessing()

    fetch(this.buildURL(), {
      dataType: "json",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.getToken()
      }
    })
    .then(response => response.json())
    .then(object => {
      this.replaceValues(object['table'])
      this.replaceURI()
      this.stopProcessing()
    })
    .catch(error => {
      console.log(error)
      this.stopProcessing()
    })
  }

  getToken() {
    let token = document.head.querySelector("[name~=csrf-token][content]")
    return (token) ? token.content : ""
  }

  buildURL() {
    let new_url = this.urlValue.split("?")
    return new_url[0] + `?interval=${encodeURI(this.interval)}`
  }

  startProcessing() {
    this.spinningIconTarget.classList.remove('hide')
    document.getElementById('analytics_cards').classList.add('disabled')
  }

  stopProcessing() {
    this.spinningIconTarget.classList.add('hide')
    document.getElementById('analytics_cards').classList.remove('disabled')
  }

  replaceValues(report) {
    Object.keys(report).forEach(item => {
      const el = document.getElementById(item)
      if (el) { el.innerHTML = report[item] }
    })
  }

  replaceURI() {
    const current_url = location.protocol + '//' + location.host + location.pathname
    const params = new URLSearchParams({ interval: this.interval })
    const new_url = current_url + '?' + params
    history.replaceState({ params: new_url }, null, new_url)

    // we also need to replace the download csv interval param
    let exportButton = document.getElementById('exportCSVButton')
    let export_url = exportButton.href
    export_url = export_url.split("?")[0] + '?' + params
    exportButton.href = export_url
  }

  calendarHandler = (selectedDates, dateStr, instance) => {
    if (selectedDates.length == 1) {
      // started selection
      this.filterButtonTarget.disabled = true
      this.removeActiveOption()
    } else if (selectedDates.length == 2) {
      // finished selection
      this.filterButtonTarget.disabled = false
      this.removeActiveOption()
      this.interval = dateStr
      this.updateRangeValue()
    }
  }
}
