import { Controller } from "stimulus"
import PreviewUtils from '../packs/preview_utils'
export default  class extends Controller {

  static values = { 
    campaign: String, 
    manageUrl: String, 
    setUrl: String
  }
  
  selectItem(event) {
    let element = event.currentTarget
    let option = element.options[element.selectedIndex]
    switch(option.value) {
      case "-1":
        window.location = this.manageUrlValue
        break
      default:
        this.setCampaignTemplate(option.value)
    }
  }

  setCampaignTemplate(template_id) {
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    let data = {
      email_template_id: template_id
    }
    fetch(this.setUrlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(data)
    })
    .then(response => response.ok)
    .then(ok => {
      console.log('set success')
      new PreviewUtils().reloadWithFormParams()
    })
    .catch((error) => {
      console.log('error', error)
    })
  }

}
