import { Controller } from "stimulus"
import { 
  Chart, BarElement, BarController, ScatterController, LineElement, LineController,
  PointElement, CategoryScale, LinearScale, Tooltip
} from 'chart.js'
import { observeDOM } from '../packs/arpu_utils';

export default class ChartController extends Controller {

  static targets = [ "chart" ]

  connect() {
    Chart.register(
      BarElement,
      BarController,
      ScatterController,
      LineElement,
      LineController,
      PointElement,
      CategoryScale,
      LinearScale,
      Tooltip,
    )
    Chart.register({
      id: 'mouseoutCatcher',
      beforeEvent: (chart, args, pluginOptions) => {
        const event = args.event;
        if (event.type === 'mouseout') {
          this.resetDatasetBackground(chart)
          chart.update('none')
        }
      }
    })
    this.render()
    if(this.filterElement) {
      observeDOM({ attributes: true }, this.filterElement, this.filterCallback)
    }
  }

  filterCallback = (entries) => {
    entries.forEach((entry) => {
      if(entry.attributeName == 'data-period-filter-url-response-value') {
        this.updateGraph(entry.target.dataset.periodFilterUrlResponseValue)
      }
    })
  }

  updateGraph(response) {
    if(!this.graph || !response) return
    try {
      this._metrics = JSON.parse(response)['metrics']
      this._metrics_versions = JSON.parse(response)['metrics_versions']
      this._labels = this.metrics.map((m) => m.date)
      this.graph.data = this.data
      this.graph.update()
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    if (!this.ele) return
   
    const ctx = this.ele.getContext('2d')
    
    this.graph = new Chart(ctx, { type: 'bar', data: this.data, options: this.options })
  }

  get url() {
    return this._url = this._url || this.element.dataset.url.split("?")[0]
  }

  get filterElement() {
    return this._filterElement = this._filterElement || document.querySelector('[data-controller="period-filter"]')
  }

  get ele() {
    return this._ele = this._ele || this.chartTarget
  }

  get metrics() {
    return this._metrics = this._metrics || JSON.parse(this.element.dataset.metrics)
  }

  get currency_symbol() {
    return this._currency_symbol = this._currency_symbol || this.element.dataset.currency
  }

  get options() {
    return { 
      responsive: true, 
      interaction: {
        intersect: false,
        mode: 'index',
      },
      maintainAspectRatio: false, 
      scales: {
        x: { stacked: true },
        y1: { 
          type: 'linear', 
          position: 'left', 
          stacked: false
        },
        y2: { 
          type: 'linear', 
          position: 'right', 
          grid: { display: false }, 
          ticks: { 
            callback: (value, index, ticks) => { return this.currency_symbol + value } 
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        'mouseoutCatcher': true,
        tooltip: {
          enabled: true,
          position: 'nearest',
          displayColors: false,
          backgroundColor: 'rgba(0,0,0,0.9)', 
          itemSort: function(a, b) {
            if(b.dataset.label == 'Revenue') return 0 
            return a.datasetIndex - b.datasetIndex
          },
          callbacks: {
            label: (context) => {
                     let label = context.dataset.label || ''
                     if (label) {
                       label += ': '
                     }
                     if (context.parsed.y !== null) {
                       if ( context.datasetIndex === 3 ) {
                         label += this.currency_symbol
                       }
                       if(label) label += context.parsed.y
                     }
                     return label
                   }
          }
        }
      },

      onHover: (evt) => {
        const chart = evt.chart
        const elements = evt.chart.getActiveElements()
        if ( elements.length > 0 ) {
          elements.filter(e => (e.element instanceof BarElement)).forEach((el) => {
            let i = el.index 
            let di = el.datasetIndex
            chart.data.datasets[di].backgroundColor = this.backgroundColorSelection(i,chart.data.datasets[di])
          })
        }
        else { 
          this.resetDatasetBackground(chart)
        }
        chart.update('none')
      }
    }
  }

  resetDatasetBackground(chart) {
    chart.data.datasets.forEach((dt) => {
      let colors = new Array(dt.data.length)
      for (let i=0; i<dt.data.length; ++i) { colors[i] = this.backgrounds[dt.order-1] }
      dt.backgroundColor = colors
    })
  }

  backgroundColorSelection(index, dataset) {
    let size = dataset.data.length
    let color = this.backgrounds[dataset.order - 1]
    const regex = /rgb\((\d+), (\d+), (\d+)\)/i
    let faded = color.replace(regex, 'rgba($1, $2, $3, 0.25)')
    let colors = new Array(size)
    for (let i=0; i<size; ++i) { colors[i] = faded }
    colors[index] = color
    return colors
  }

  get backgrounds() { 
   return [ 'rgb(56, 192, 156)', 'rgb(97, 248, 208)', 'rgb(215, 242, 235)' ]
  }

  get data() {
    return { labels: this.labels, datasets: this.datasets }
  }

  get labels() {
    return this._labels = this._labels || this.metrics.map((m) => m.date)
  }

  get sent_datasets() {
    return {
        label: 'Sent',
        data: this.metrics.map((m) => parseInt(m.emails_sent, 10)),
        type: 'bar',
        order: 3,
        fill: true,
        backgroundColor: this.backgrounds[2],
        borderColor: 'rgb(75, 192, 192)',
        yAxisID: 'y1'
      }
  }

  get opened_datasets() {
    return {
        label: 'Opened',
        data: this.metrics.map((m) => parseInt(m.open_count, 10)),
        type: 'bar',
        order: 2,
        fill: true,
        backgroundColor: this.backgrounds[1],
        borderColor: 'rgb(255, 192, 192)',
        yAxisID: 'y1'
      }
  }

  get clicked_datasets() {
    return {
        label: 'Clicked',
        data: this.metrics.map((m) => parseInt(m.click_count, 10)),
        type: 'bar',
        order: 1,
        fill: true,
        backgroundColor: this.backgrounds[0],
        borderColor: 'rgb(255, 192, 192)',
        yAxisID: 'y1'
      }
  }

  get revenue_datasets() {
    return {
        label: 'Revenue',
        data: this.metrics.map((m) => parseInt(m.revenue, 10)),
        type: 'line',
        order: 0,
        fill: false,
        backgroundColor: '#35464F',
        borderColor: '#35464F',
        borderWidth: 2, 
        tension: 0.1,
        yAxisID: 'y2'
      }
  }

  get datasets() {
    return [this.sent_datasets, this.opened_datasets, this.clicked_datasets, this.revenue_datasets] 
  }
}

