import { Controller } from 'stimulus'
import PreviewUtils from '../packs/preview_utils'
import { debounce, debounceLeading, throttle } from '../packs/form_utils'

class PreviewIframeController extends Controller {
  static targets = ['featuredItem', 'iframe', 'selectPreview', 'loading']
  static values = {
    variablePricePrefix: String
  }

  connect() {
    this.processReload();
    this.element[this.identifier] = this
    this.debounceReload = debounce(this.processReload, 1000)
    this.throttleReload = throttle(this.processReload, 1000)
    this.reload         = debounceLeading(this.processReload)
  }

  processReload() {
    const iframeTarget = document.querySelector('iframe')
    const previewUtils = new PreviewUtils(iframeTarget)
    previewUtils.reloadWithFormParams()
  }
}

export default PreviewIframeController

