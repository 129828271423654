import { Controller } from 'stimulus'

class FlashController extends Controller { 
  static values = { timer: Number }
  connect() {
    this.closeTimer = setTimeout(this.close, this.timerValue);
  }

  disconnect() {
    clearTimeout(this.closeTimer);
  }

  close = () => {
    this.element.remove();
  };
}

export default FlashController