import { Controller } from "stimulus"
import { arpu_currency_formatting } from "../packs/arpu_utils.js"

export default class extends Controller {
  static targets = [ "image", "price", "comparedAtPrice", "item" ]
  static values = { 
    discount: String,
    moneyFormattingJsNumberFormat: String,
    moneyFormattingPrefix: String,
    moneyFormattingSuffix: String,
    currency: String
  }

  optionSelected(event) {
    const element = event.currentTarget
    const option = element.options[element.selectedIndex]
    let selected_item = JSON.parse(option.dataset.object)
    if (!!selected_item.featured_image_src) {
      this.imageTarget.src = selected_item.featured_image_src
    }
    
    // TODO use arpu formatting function
    this.priceTarget.innerHTML = `$${selected_item.price}`

    if(!!selected_item.compared_at_price) {
      this.comparedAtPriceTarget.innerHTML = `$${Number(selected_item.compared_at_price).toFixed(2)}`
    }

   this.itemTarget.dataset.item = option.dataset.object

  }

  moneyFormat(sale,price,compared_at_price) {
    let nsale = Number(sale)
    let nprice = Number(price)
    let format = this.moneyFormattingJsNumberFormatValue
    let prefix = this.moneyFormattingPrefixValue
    let suffix = this.moneyFormattingSuffixValue
    let currency_code = this.currencyValue

    let ncompared_at_price = Number(compared_at_price)

    let l_nsale = arpu_currency_formatting(nsale, prefix, format, suffix, currency_code)
    let l_nprice = arpu_currency_formatting(nsale, prefix, format, suffix, currency_code)
    let l_ncompared_at_price = arpu_currency_formatting(nsale, prefix, format, suffix, currency_code)

    if ((nsale < nprice) && (l_nsale != l_nprice)){
      return l_nprice
    }
    else {
      if ((nprice < ncompared_at_price) && (l_nprice != l_ncompared_at_price)) {
        return l_ncompared_at_price
      }
      else return ""
    }
  }

  
}

