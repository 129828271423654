import { Controller } from 'stimulus'

class ModalController extends Controller {
  static targets = ['showBtn', 'closeBtn', 'container']
  static values =  { 'linkedContainerId': String }
  static classes = ['active', 'inactive', 'visible']

  connect() {
    this.element[this.identifier] = this
  }
  
  show(e) {
    if(e) e.preventDefault()
    this.container.classList.add(this.visibleCssClass)
  }

  hide(e) {
    if(e) e.preventDefault()
    this.container.classList.remove(this.visibleCssClass)
  }

  get visibleCssClass() {
    return this.hasVisibleClass ? this.visibleClass : 'visible' // Default value
  }

  get container() {
    if (!this.hasContainerTarget && this.hasLinkedContainerIdValue) {
      return document.getElementById(this.linkedContainerIdValue)
    } else {
      return this.containerTarget
    }
  }
}

export default ModalController