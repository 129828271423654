import { Controller } from "stimulus"
import Bugsnag from '@bugsnag/js'
import { Cashier, CurrencyFormatter } from "../packs/checkout"

export default class extends Controller {

  static targets = ["cart", "subtotal", "cartEmpty", "cartFooter", "oneTimeExtras", "cartCount", "cartItem", "qty", "processing", "product", "cartStep1", "cartStep2", "cartStep1Button", "cartStep2Button", "giftName", "giftAddress", "giftEmail", "giftPhone", "giftMessage", "giftMessageNow", "giftDate", "error"]

  static values = {
    oneTimeExtras: Array,
    purchaseUrl: String,
    initialProduct: Number,
    moneyFormattingPrefix: String,
    moneyFormattingJsNumberFormat: String,
    moneyFormattingSuffix: String,
    currency: String
  }

  connect() { 
    this.addPreselectedItem()
  }

  initialize() {
    this.currencyFormatter = new CurrencyFormatter(
      this.moneyFormattingPrefixValue,
      this.moneyFormattingJsNumberFormatValue,
      this.moneyFormattingSuffixValue,
      this.currencyValue
    );
    this.cashier = new Cashier([], this.currencyFormatter);
  }

  getToken() {
    let token = document.head.querySelector("[name~=csrf-token][content]")
    return (token) ? token.content : ""
  }

  goBack() {
    this.cartStep2Target.classList.contains('filter--notMatch')
      ? this.hideCart()  // Step 1: Hide cart
      : this.goToStep1() // Step 2: Go back to Step 1
  }

  goToStep1() {
    this.cartStep1Target.classList.remove('filter--notMatch')
    this.cartStep2Target.classList.add('filter--notMatch')

    this.cartStep1ButtonTarget.classList.remove('filter--notMatch')
    this.cartStep1ButtonTarget.classList.add('button')

    this.cartStep2ButtonTarget.classList.add('filter--notMatch')
    this.cartStep2ButtonTarget.classList.remove('button')
  }

  goToStep2() {
    this.cartStep1Target.classList.add('filter--notMatch')
    this.cartStep2Target.classList.remove('filter--notMatch')

    this.cartStep1ButtonTarget.classList.add('filter--notMatch')
    this.cartStep1ButtonTarget.classList.remove('button')

    this.cartStep2ButtonTarget.classList.remove('filter--notMatch')
    this.cartStep2ButtonTarget.classList.add('button')
  }

  addToNextOrder(event) {
    if (this.isInvalidForm()) return
    this.startProcessing() 

    let formData = new FormData()
    let items = this.cashier.lineItems.flatMap(x => [{ id: x.gid_uri, qty: x.qty, featured: x.featured }]) 

    // Append giftee info
    let shippingAddress = {}
    this.giftAddressTargets.forEach(field => { shippingAddress[field.name] = field.value })
    formData.append('items', JSON.stringify(items))
    formData.append('[gift][shipping_address]', JSON.stringify(shippingAddress))
    formData.append('[gift][name]', this.giftNameTarget.value)
    formData.append('[gift][email]', this.giftEmailTarget.value)
    formData.append('[gift][phone]', this.giftPhoneTarget.value)
    formData.append('[gift][message]', this.hasGiftMessageTarget ? this.giftMessageTarget.value : '')
    formData.append('[gift][messagenow]', this.hasGiftMessageNowTarget ? this.giftMessageNowTarget.checked : '')
    formData.append('[gift][scheduled_at]', this.giftDateTarget.value)
    formData.append('[gift][total_price]', this.subtotalTarget.innerHTML) 

    fetch(this.purchaseUrlValue, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': this.getToken()
      }
    })
      .then(response => {
        if (response.ok) {
          if (response.redirected) {
            window.location.href = response.url
          } else {
            return this.renderPreviewThankYou(response)
          }
        } else {
          response.json().then(data => {
            Bugsnag.notify(data)
          })
          this.showErrorMessage()
        }
        this.stopProcessing()
      })
      .catch(error => {
        console.log(error)
        this.stopProcessing()
      })
  }

  renderPreviewThankYou(response) {
    response.text()
      .then(data => {
        if (data !== undefined) {
          document.open()
          document.write(data)
          document.close()
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.stopProcessing()
      })
  }

  isInvalidForm() {
    let invalid = false
    const isMissingAddress = this.giftAddressTargets.filter(field => field.value.trim() === '').length > 0
    let errorFields = [
      { target: document.querySelector('[data-error-type="name"]'), isInvalid: this.giftNameTarget.value.trim() == '' },
      { target: document.querySelector('[data-error-type="email"]'), isInvalid: this.giftEmailTarget.value.trim() == ''},
      { target: document.querySelector('[data-error-type="shippingAddress"]'), isInvalid: isMissingAddress }
    ]

    errorFields.forEach(field => {
      field.target.classList.add('filter--notMatch')
      if(field.isInvalid) {
        invalid = true
        field.target.classList.remove('filter--notMatch')
      }
    })
    return invalid
  }

  /* actions */
  tossMore(event) {
    this.goToStep1()
    let extraItem = JSON.parse(event.currentTarget.getAttribute("data-item"))
    if (!extraItem.featured_image_src) {
      extraItem['featured_image_src'] = event.currentTarget.getAttribute('data-item-img')
    }
    this.addToCart(extraItem)
  }

  addPreselectedItem() {
    // update your element here
    let selectedProduct = this.productTargets.find(target => target.getAttribute('data-item-id') == this.initialProductValue)
    if (selectedProduct !== undefined) {
      const extraItem = JSON.parse(selectedProduct.getAttribute('data-item'))
      this.addToCart(extraItem)
    }
  }

  addToCart(extraItem) {
    if (this.cashier.scan(extraItem, 1)) {
      this.updateQtyWidget(extraItem.id, this.cashier.itemQuantity(extraItem))
      this.showCart()
    } else {
      this.cashier.include(extraItem, 1)
      this.buildHTMLForExtraItem(extraItem)
    }
    this.updateCartItemsCount()
    this.updateSubtotal()
  }

  updateQtyWidget(id, qty) {
    for (let i = 0; i < this.qtyTargets.length; i++) {
      let oid = this.qtyTargets[i].getAttribute('data-item-id')
      if (oid == id) {
        this.qtyTargets[i].setAttribute('value', qty)
        break
      }
    }
  }

  startProcessing() {
    this.processingTarget.classList.add('visible')
  }

  stopProcessing() {
    this.processingTarget.classList.remove('visible')
  }

  showErrorMessage() {
    this.cashier.clear();
    this.cartTarget.classList.add('filter--notMatch')
    this.errorTarget.classList.add('visible')
  }

  updateSubtotal() {
    this.subtotalTarget.innerHTML = this.cashier.subtotalFormatted;
  }

  showCart(event) {
    this.cartTarget.classList.add('visible')
  }

  hideCart(event) {
    this.cartTarget.classList.remove('visible')
  }

  updateCartItemsCount() {
    const cart_count = this.cartCountTarget
    if (this.cashier.lineItems.length > 0) {
      cart_count.innerHTML = this.cashier.lineItemsCount
      this.cartEmptyTarget.setAttribute("class", "filter--notMatch")
      this.cartFooterTarget.setAttribute("class", "cart-footer")
      cart_count.classList.add('cart-count')
    }
    else {
      cart_count.innerHTML = ""
      cart_count.classList.remove('cart-count')
      this.cartEmptyTarget.setAttribute("class", "cart-empty")
      this.cartFooterTarget.setAttribute("class", "filter--notMatch")
    }
    this.showCart()
  }

  buildHTMLForExtraItem(item) {
    const e = document.createElement('div')
    e.classList.add("cart-item")
    e.setAttribute('data-checkout-gift-target', "cartItem")
    e.setAttribute('data-item-id', item.id)

    const e_image = document.createElement('div')
    e_image.classList.add("item-image")

    const image = document.createElement('img')
    image.setAttribute("src", `/featured_items/${encodeURIComponent(item.gid_uri)}/featured_image_src?last_image=${item.featured_image_src}`)

    const e_info = document.createElement('div')
    e_info.classList.add("item-info")

    const product_title = document.createElement('div')
    product_title.classList.add("product-title")
    product_title.innerHTML = item.title

    const variant_title = document.createElement('div')
    variant_title.classList.add("variant-title")
    variant_title.innerHTML = item.variant_title

    // price block
    const variant_price = document.createElement('div')
    const variant_price_discount = document.createElement('span')

    variant_price_discount.classList.add("money", "sale")
    variant_price_discount.innerHTML = this.currencyFormatter.format(item.sale_price)
    variant_price.appendChild(variant_price_discount)

    if (item.sale_price != item.price) {
      const variant_price_compare_at = document.createElement('span')
      variant_price_compare_at.classList.add("money", "compare-at")
      variant_price_compare_at.innerHTML = '&nbsp;' + this.currencyFormatter.format(item.price)
      variant_price.appendChild(variant_price_compare_at)
    }
    else {
      if ((item.compared_at_price) && (item.sale_price < item.compared_at_price)) {
        const variant_price_compare_at = document.createElement('span')
        variant_price_compare_at.classList.add("money", "compare-at")
        variant_price_compare_at.innerHTML = '&nbsp;' + this.currencyFormatter.format(item.compared_at_price)
        variant_price.appendChild(variant_price_compare_at)
      }
    }

    e_image.appendChild(image)
    e.appendChild(e_image)

    e_info.appendChild(product_title)
    e_info.appendChild(variant_title)
    e_info.appendChild(variant_price)

    const [quantity, quantity_widget, btn_minus, qty_input, btn_plus] =
      this.buildQtyButtonForExtraItem(item.id);

    if (item.sale_price == 0.0) {
      // Put Free Text with Quantity to the Item's Description
      const qnty_text = document.createElement('span')
      qnty_text.setAttribute("class", "heading-text")
      qnty_text.innerHTML = "&nbsp;&times;&nbsp;1"
      product_title.append(qnty_text)

      const free_item = document.createElement('span')
      free_item.classList.add("money", "sale")
      free_item.innerHTML = "Free&nbsp;"
      variant_price.prepend(free_item)

      // Disable inputs
      btn_plus.setAttribute("disabled", "disabled")
      qty_input.setAttribute("disabled", "disabled")
    }
    else {
      this.buildQtyButtonForExtraItem(item.id)
    }

    quantity_widget.appendChild(btn_minus)
    quantity_widget.appendChild(qty_input)
    quantity_widget.appendChild(btn_plus)

    quantity.appendChild(quantity_widget)

    e_info.appendChild(quantity)
    e.appendChild(e_info)

    /* insert on top of the list */
    this.oneTimeExtrasTarget.insertBefore(e, this.oneTimeExtrasTarget.firstChild)
  }

  buildQtyButtonForExtraItem(itemId, type = 'otp') {
    const quantity = document.createElement('div')
    quantity.classList.add("quantity")

    const quantity_widget = document.createElement('div')
    quantity_widget.classList.add("quantity-widget", "small")

    const btn_minus = document.createElement('button')
    btn_minus.innerHTML = '<i class="fa fa-minus" aria-hidden="true"></i>'
    btn_minus.setAttribute("type", "button")
    btn_minus.setAttribute("data-action", "click->checkout-gift#qtyMinus")
    btn_minus.setAttribute("data-item-id", itemId)
    btn_minus.setAttribute("data-purchase-type", type)
    btn_minus.classList.add("quantity-control", "minus")

    const qty_input = document.createElement('input')
    qty_input.setAttribute("value", 1)
    qty_input.setAttribute("type", "number")
    qty_input.setAttribute("min", "0")
    qty_input.setAttribute("patern", "\d*")
    qty_input.setAttribute("data-checkout-gift-target", "qty")
    qty_input.setAttribute("data-action", "input->checkout-gift#qtyChange")
    qty_input.setAttribute("data-item-id", itemId)
    qty_input.setAttribute("data-purchase-type", type)
    qty_input.classList.add("quantity-input")

    const btn_plus = document.createElement('button')
    btn_plus.innerHTML = '<i class="fa fa-plus" aria-hidden="true"></i>'
    btn_plus.setAttribute("type", "button")
    btn_plus.setAttribute("data-action", "click->checkout-gift#qtyPlus")
    btn_plus.setAttribute("data-item-id", itemId)
    btn_plus.setAttribute("data-purchase-type", type)
    btn_plus.classList.add("quantity-control", "plus")

    return [quantity, quantity_widget, btn_minus, qty_input, btn_plus]
  }

  getQtyTarget(id) {
    let e = this.qtyTargets.filter(x => x.getAttribute('data-item-id') == id)
    return e[0]
  }

  removeCartItem(id) {
    for (let i = 0; i < this.cartItemTargets.length; i++) {
      let item_id = this.cartItemTargets[i].getAttribute('data-item-id')
      if (id == item_id) {
        this.cartItemTargets[i].remove()
        break
      }
    }
  }

  qtyPlus(event) {
    const e = event.currentTarget;
    const item_id = e.getAttribute("data-item-id");
    const qty_item = this.getQtyTarget(item_id);
    const item = this.cashier.findLineItemById(item_id);

    if(item) {
      this.cashier.add(item, 1);
      qty_item.value = item.qty;
      this.updateCartItemsCount();
      this.updateSubtotal();
    }
  }

  qtyMinus(event) {
    const e = event.currentTarget;
    const item_id = e.getAttribute("data-item-id");
    const item = this.cashier.findLineItemById(item_id);
    const qty_item = this.getQtyTarget(item_id);
    
    if(item) {
      this.cashier.reduce(item, 1);
      qty_item.value = item.qty;
      if(!this.cashier.findLineItem(item)) {
        e.disabled = true;
        this.removeCartItem(item_id);
      }
      this.updateCartItemsCount();
      this.updateSubtotal();
    }
  } 
  
  qtyChange(event) {
    if(Number(event.currentTarget.value)) {
      const itemId = event.currentTarget.getAttribute('data-item-id');
      const item = this.cashier.findLineItemById(itemId);
      this.cashier.modifyLineItemQuantity(item, Number(event.currentTarget.value)); 
      this.updateCartItemsCount();
      this.updateSubtotal();
    }
  }
}

