import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['notesList', 'noteBody', 'errorMessage']

  validateNoteBody(event) {
    console.log("before ajax")
    if (this.noteBodyTarget.value == '') {
      this.errorMessageTarget.innerHTML = "Note can't be empty"
      return false
    }
  }
  
  createSuccess(event) {
    const [_data, status, xhr] = event.detail
    this.notesListTarget.innerHTML = xhr.response + this.notesListTarget.innerHTML;
    this.noteBodyTarget.value = '';
  }

  destroy(event) {
    const [_data, _status, xhr] = event.detail
    const note_id = JSON.parse(xhr.response)['note_id']
    const note = document.getElementById(`account-note-${note_id}`)
    note.remove()
  }
}