import { Controller } from 'stimulus'
import Cookie from 'js-cookie';

export default class extends Controller {
  static values = { name: String }
  static classes = ['hide']

  dismiss(event) {
    if(this.confirmDismiss()) {
      this.addDismissCookie();
      this.hide();
    }
  }

  confirmDismiss() {
    return confirm('Are you sure you want to dismiss this announcement?') 
  }

  addDismissCookie() {
    let dismissed_announcements = (Cookie.get('dismissed_announcements') || '').split(',')
    if(!dismissed_announcements.includes(this.nameValue)) {
      dismissed_announcements.push(this.nameValue)
      Cookie.set('dismissed_announcements', dismissed_announcements)
    }
  }

  hide() {
    this.element.classList.add(this.hideCssClass)
  }

  get hideCssClass() {
    return this.hasHideClass ? this.hideClass : 'filter--notMatch' // Default value
  }
}

