import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['record']
  static values = { statusUrl: String, recordId: Number, attached: Boolean }

  connect() {
    if(this.hasStatusUrlValue) {
      setTimeout(() => {
        this.retrieverecordStatus()
      }, 5000)
    }
  }

  retrieveStatus(){
    let in_progress = false

    for(var i=0; i < this.recordTargets.length ; i++) {
      const tr = this.recordTargets[i]
      if(tr.dataset.attached == 'true' ) continue 
      else { in_progress = true }
      this.getStatus(tr.dataset.record)
    }

    if(in_progress) {
      setTimeout(() => {
        this.retrieveStatus()
      }, 20000)
    }
  }

  getStatus(id) {
    let token = document.head.querySelector("[name~=csrf-token][content]")
    fetch(this.statusUrlValue, {
      headers: { 'X-CSRF-Token': token }
    })
    .then(response => response.text())
    .then(data => {
      this.updateStatus(id, data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  updateStatus(id, data) {
    for(var i=0; i < this.recordTargets.length ; i++) {
      const tr = this.recordTargets[i]
      if(tr.dataset.record == id ) { 
        tr.innerHTML = data
      }
    }
  }
}