import { Controller } from "stimulus"
import { reject, throttle } from "lodash"

export default class extends Controller {
  static targets = [ "source", "filterable","filterbox" ]
  static canHide = true
  connect() {
    this.throttleFilter = throttle(this.filter, 500)
  }
  openList(event) {
    this.filterboxTarget.classList.remove('filter--notMatch')
  }
  closeList(event) {
    if(this.sourceTarget.value.length < 1) {
      if(this.canHide) this.filterboxTarget.classList.add('filter--notMatch')
    }
  }
  enableClose(event){
    this.canHide = true
  }
  disableClose(event){
    this.canHide = false
  }
  resetSource(event){
   this.sourceTarget.value = ""
   this.canHide = true
   this.closeList(event)
  }

  async filter(event) {
    //-- Logic to find current items inside a list
    // let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
    // this.filterableTargets.forEach((el, i) => {
    //   let filterableKey =  el.getAttribute("data-filter-key")
    //   el.classList.toggle("filter--notMatch", !filterableKey.includes( lowerCaseFilterTerm ) )
    // })
    //await this.delay(500);
    // if(this.emptyTargets()) {
    //   this.element.search.debounceUpdate(event)
    // }

    //-- Always search in Ecommerce whenever filtering 
    this.filterableTargets.forEach((el, i) => {
      el.classList.toggle("filter--notMatch", true)
    })

    await this.delay(400);
    this.element.search.debounceUpdate(event)
  }

  emptyTargets() {
    const listHide = el => el.classList.contains("filter--notMatch")
    const showingList = reject(this.filterableTargets, listHide)
    return showingList.length < 1
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
} 
