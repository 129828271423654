import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Sortable from "sortablejs";

export default class extends Controller {
  static classes = ["move"];

  connect() {
    this.sortable = Sortable.create(this.element, {
      onStart: this.startDrag.bind(this),
      onEnd: this.endDrag.bind(this),
      dragClass: "drag",
    });
  }

  startDrag(event) {
    event.item.classList.add(this.moveClass);
  }

  async endDrag(event) {
    // Campaigns logic
    if (this.data.get("url")) {
      let id = event.item.dataset.id;
      let data = new FormData();
      data.append("position", event.newIndex + 1);

      Rails.ajax({
        url: this.data.get("url").replace(":id", id),
        type: "PATCH",
        data: data,
      });
    } else {
      // Feature Products logic
      let inputs = document.querySelectorAll('input[id*="position"]');

      let i = 1;
      inputs.forEach(function (item) {
        item.value = i;
        i++;
      });
    }

    event.item.classList.remove(this.moveClass);
  }
}
