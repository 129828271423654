import './index.scss'
import { Controller as BaseController } from 'stimulus'
import { observeDOM } from '../../javascript/packs/arpu_utils';

export class Controller extends BaseController {

  static targets = [ 'option', 'filter']
  static classes = [ 'selected' ]

  connect() {
    this.selectDefaultOption()
    const defaultGroup = document.getElementById('default_period_filter_group')
    observeDOM({ attributes: true }, defaultGroup, this.groupCallback)
  }

  groupCallback = (entries) => {
    entries.forEach((entry) => {
      if(entry.attributeName == 'value') {
        this.selectDefaultOption()
      }
    })
  }

  selectDefaultOption() {
    const defaultGroup = document.getElementById('default_period_filter_group')
    this.optionTargets.forEach((el)=> {
      if(el.innerHTML.trim().toLowerCase() == defaultGroup.value){
        this.toggleOption(el)
      }
    })
  }
  
  optionSelect(event) {
    const option = event.currentTarget

    this.toggleOption(option)

    /* set period filter group value */
    const group = document.getElementById('period_filter_group')
    group.value = option.innerHTML.trim().toLowerCase()
    if(this.periodFilter) { 
      this.periodFilter.filter()
    }
  }

  toggleOption(option) {
    this.optionTargets.forEach((el)=> {
      el.classList.remove(this.selectedClass)
    })
    option.classList.add(this.selectedClass)
  }

  get periodFilter() {
    const periodFilterId = this.element.dataset.periodFilterId;
    if(periodFilterId) {
      return document.querySelector(`#${periodFilterId}`)['period-filter']
    }
  }
}
