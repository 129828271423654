import { Controller } from "stimulus"
import { arpu_showNotificationError } from "../packs/arpu_utils.js"

export default class extends Controller {
  static values = { url: String }
  update(event) {
    let token = document.head.querySelector("[name~=csrf-token][content]").content
    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
       },
    })
    .then(response => {
      if (!response.ok) {
        response.json().then(data => {
          const toggler = event.target
          arpu_showNotificationError(data.error)
          this.revertToggler(toggler)
        })
      }
    })
  }

  revertToggler(toggler){
   const checkbox = toggler.previousElementSibling
   checkbox.disabled = true
   checkbox.checked = false
  }

}
