import { Controller } from "stimulus"
import { startCase } from "lodash"

export default class extends Controller {

  static targets = ["priceAdjustments", "adjustmentValue", "adjustmentStrategy", "discountStrategy", "discountStrategies", "template", "addLinksHere", "subjectOption", "subjectLabel", "subjectOptionIndex", "saveButton"]
  static values = { "discountStrategies": Array, "creditStrategies": Array }

  selectAdjustmentStrategy(event) {
    this.discountStrategiesTarget.innerHTML = ''
    let s = this.strategies
    this.strategies.forEach ((strategy) => {
      let el = document.createElement('option')
      el.value = strategy
      el.innerHTML = startCase(strategy)
      this.discountStrategiesTarget.appendChild(el)
    })
    this.discountStrategiesTarget.dispatchEvent(new Event('change'))
  }

  addOption(event) {
    let template_id = new Date().valueOf()
    let element_count = this.subjectOptionTargets.length
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, template_id)
    content = content.replace(/INDEX/g, element_count)
    content = content.replace(/BUTTON_NUMBER/g, element_count + 1)
    this.addLinksHereTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeOption(event) {
    const element = event.currentTarget
    let index = element.dataset.index
    if (this.subjectOptionTargets[index].dataset.persisted == 'true') {
      document.getElementById(`finance_app_subscription_price_adjustments_attributes_${index}__destroy`).value = true
    }
    this.subjectOptionTargets[index].remove()
    this.reIndex()
  }

  reIndex() {
    for (let i = 0; i < this.subjectOptionTargets.length; i++) {
      let j = i + 1
      this.subjectOptionIndexTargets[i].dataset.index = i
    }
  }

  get strategies() {
    let strategy = this.adjustmentStrategyTarget.value
    return this[`${strategy}StrategiesValue`]
  }
}
