import './index.scss';
import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = [ 'card' ]
  
  connect() {}

  selectRadioCard(event) {
    this.cardTargets.forEach((element) => {
      element.classList.remove(["c-form--radio-selected"]);
      element.querySelector('input').checked = false;
      });

      event.currentTarget.classList.add(['c-form--radio-selected'])
      event.currentTarget.querySelector('input').checked = true
    }
  }
