import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'link', 'content' ]

  initialize() {
    this.contentTarget.style.display = 'none'
  }

  toggle(event){
    event.preventDefault()

    if (this.contentTarget.style.display === 'none') {
      this.contentTarget.style.display = 'block'
    } else {
      this.contentTarget.style.display = 'none'
    }
  }
}