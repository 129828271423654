import './index.scss';
import { Controller as BaseController } from 'stimulus';
import { DateTime, Interval } from 'luxon';
import { observeDOM, jsonRequestOptions } from '../../javascript/packs/arpu_utils';

export class Controller extends BaseController {
  static targets = [ 'wrapper', 'datepickerWrapper', 'datepicker', 'dateField', 'selectFilterField', 'filterButton' ]
  static values = { url: String, urlResponse: String, filtered: String, interval: String, selected: String, group: String }

  connect() {
    // We have to wait a little as the flatpickr controller is mounted after ours
    this.element[this.identifier] = this
    this.initializeInterval();
    this.filter();
    setTimeout(() => {
      this.flatpickrInstance = this.datepickerTarget._flatpickr
      this.flatpickrInstance.config.onChange.push(this.dateValueCallback)
      if(this.intervalValue) {
        this.flatpickrInstance.setDate(this.intervalValue.split(' - '), false, "m/d/Y");
      }
      observeDOM({ attributes: true }, this.selectFilterFieldTarget, this.selectedValueCallback);
      document.addEventListener('click', this.clickOutsideCallback); 
    }, 500);
  }

  initializeInterval() {
    let urlParamInterval = this.intervalParamHumanize();
    if(urlParamInterval) {
      const rangeDate = urlParamInterval.split(' to ')
      const interval = Interval.fromDateTimes(DateTime.fromISO(rangeDate[0]), DateTime.fromISO(rangeDate[1]));
      const intervalHumanized = this.humanizeInterval(interval);
      if(interval.isValid) { 
        const intervalMapping = this.intervalMappingByDateRange[intervalHumanized]; 
        if(intervalMapping) {
          this.selectedValue = intervalMapping.key;
        } else { 
          this.selectedValue = 'custom';
        }
        this.interval = interval
        this.intervalValue = intervalHumanized;
        this.setFilterTarget(interval);
      }
    }
  }
 
  // Hooks 
  //
  intervalValueChanged(interval) {
    if(!interval) return

    this.dateFieldTarget.value = interval; 

    if(this.contentInterval) {
      this.contentInterval.innerText = this.interval.end.minus({days: 1}).toLocaleString();
    }
  }

  selectedValueChanged(intervalName) {
    if(!intervalName) return;

    const [methodName, date, toDate] = this.intervalsMap[intervalName].call_to_method;
    if(methodName && methodName == 'custom') {
      this.toggleDatepicker();
    } else if(methodName) {
      this.interval = this[methodName](date, toDate)
      this.intervalValue = this.humanizeInterval(this.interval); 
      if(this.flatpickrInstance)
        this.flatpickrInstance.setDate(this.intervalValue.split(' - '), false, "m/d/Y");
    }
  }

  // Callbacks
  //
  selectedValueCallback = (changes) => {
    const change = changes.find((change) => {
      if(change.attributeName == 'data-form--select-box-selected-id-value') return change;
    })
    if(change && (this.selectedValue != change.target.dataset['form-SelectBoxSelectedIdValue'])) {
      this.selectedValue = change.target.dataset['form-SelectBoxSelectedIdValue'];

      setTimeout(() => {
        if(this.selectedValue != 'custom') {
          this.filter()
        } else {
          this.datepickerWrapperTarget.classList.remove('c-period-filter-hide');
        }
      }, 100);
    }
  }

  dateValueCallback = (selectedDates, dateStr, instance) => {
    if(selectedDates.length != 2) { 
      this.setFilterTarget(dateStr);
    }
    if(selectedDates.length == 2) {
      this.interval = Interval.fromDateTimes(DateTime.fromJSDate(selectedDates[0]), DateTime.fromJSDate(selectedDates[1]));
      this.intervalValue = this.humanizeInterval(this.interval);
      this.setFilterTarget(this.intervalValue);
      this.toggleDatepicker();
      this.filter();
    }
  }

  clickOutsideCallback = (event) => {
    if (!this.datepickerWrapperTarget.parentElement.contains(event.target)) {
      this.hideDatepicker();
    }
  }

  // Actions
  //
  dateFieldClick() {
    this.toggleDatepicker();
  }

  filter() {
    this.filteredValue = this.intervalParam;
    try {
      let url = this.buildURL();
      if(!url) return;
      
      fetch(url, jsonRequestOptions())
      .then(response => response.json())
      .then(object => {
        this.urlResponseValue = JSON.stringify(object);
        this.replaceValues(object['table'])
        this.updateGroup(object['group_by'])
        this.updateCampaignVersions(object['campaign_versions'])
        this.updateUpsoldProducts(object['upsold'])
        this.updateDelayedProducts(object['delayed'])
        this.replaceURI()
      })
      .catch(error => {
        console.log(error)
      })
    }
    catch(e) {
      console.log(e)
    }
  }

  // DOM Manipulation
  //
  setFilterTarget() {
    const mappedInterval =  this.intervalMappingByDateRange[this.intervalValue];
    let intervalName = 'Custom';
    if(mappedInterval) {
      intervalName = (mappedInterval.name || 'Custom');
    } 
    this.selectFilterFieldTarget.dataset['form-SelectBoxSelectedValue'] = intervalName;
  }

  replaceValues(data) {
    Object.keys(data).forEach(item => {
      const el = document.getElementById(item)
      if (el) { el.innerHTML = data[item] }
    })
  }

  updateGroup(value) {
    const el = document.getElementById('default_period_filter_group')
    const currentSelectedEl = document.getElementById('period_filter_group')
    el.value = value
    currentSelectedEl.value = ''
  }

  updateDelayedProducts(data){
    const el = document.getElementById('delay_products_info')
    const delay_el = document.querySelector('#delay_contents')
    if(el) { 
      el.dataset.delayReportDataValue = JSON.stringify(data)
    }
    if(delay_el) {
      const el_template = delay_el.content.firstChild.nextElementSibling
      el_template.dataset.delayReportDataValue = JSON.stringify(data)
      const delay_update_event = new CustomEvent("delay-update")
      window.dispatchEvent(delay_update_event)
    } 
  }

  updateUpsoldProducts(data){
    const el = document.getElementById('upsold_products_info')
    const upsold_products_el = document.querySelector('#upsold_contents') 
    if(el) {
      el.dataset.upsoldReportDataValue = JSON.stringify(data)
    }
    if(upsold_products_el) {
      const el_template = upsold_products_el.content.firstChild.nextElementSibling
      el_template.dataset.upsoldReportDataValue = JSON.stringify(data)
      const upsold_update_event = new CustomEvent("upsold-update")
      window.dispatchEvent(upsold_update_event)
    }
  }

  updateCampaignVersions(data){
    let el = document.querySelector('#campaign_versions_info') || document.querySelector('#campaign_version_row')
    const campaign_version_contents = document.querySelector('#campaign_versions_contents')
    if(el) {
      el.dataset.campaignVersionsReportDataValue = JSON.stringify(data)
    }
    if(campaign_version_contents) {
      const el_template = campaign_version_contents.content.firstChild.nextElementSibling
      el_template.dataset.campaignVersionsReportDataValue = JSON.stringify(data) 
      const campaign_version_update_event = new CustomEvent("campaign-versions-update")
      window.dispatchEvent(campaign_version_update_event)
    }
  }

  replaceURI() {
    const current_url = location.protocol + '//' + location.host + location.pathname
    const url = new URL(location)
    const params = new URLSearchParams(url.search)
    params.set('interval', this.intervalParam)
    const new_url = current_url + '?' + params.toString()
    history.replaceState({ params: new_url }, null, new_url)

    // we also need to replace the download csv interval param
    let exportButton = document.getElementById('exportCSVButton')
    if(exportButton) {
      let export_url = exportButton.href
      export_url = export_url.split("?")[0] + '?' + params
      exportButton.href = export_url
    }
  }

  toggleDatepicker() {
    this.datepickerWrapperTarget.classList.toggle('c-period-filter-hide');
  } 

  hideDatepicker() {
    this.datepickerWrapperTarget.classList.add('c-period-filter-hide');
  } 

  // Helpers
  //
  get groupBy(){
    return document.getElementById('period_filter_group').value
  }

  get contentInterval() {
    return this._contentInterval = this._contentInterval || document.getElementById('content-interval'); 
  }

  get intervalParam() {
    if(this.interval.isValid) {
      return `${this.interval.start.toISODate()} to ${this.interval.end.toISODate()}`
    }
  }

  get intervalMappingByDateRange() {
    return Object.keys(this.intervalsMap).reduce((list, key) => {
      let [methodName, date, toDate] = this.intervalsMap[key].call_to_method;
      if(methodName) {
        let interval = this.humanizeInterval(this[methodName](date, toDate));
        list[interval] = { name: this.intervalsMap[key].name, key: key };
      }
      return list;
    }, {});
  }

  intervalParamHumanize() {
    const queryString = window.location.search;
    return new URLSearchParams(queryString).get('interval');
  }

  buildURL() {
    let url = new URL(this.urlValue)
    let params = new URLSearchParams(url.search)
    params.set('interval', this.intervalParam)
    params.set('group_by', this.groupBy)
    return url.origin + url.pathname + '?' + params.toString();
  }

  getAllTimeInterval(date = DateTime.now(), to_date = false) {
    let minDate = DateTime.fromISO('2000-01-01');
    return Interval.fromDateTimes(minDate, DateTime.now());
  }

  getYearInterval(date = DateTime.now(), to_date = false) {
    const startDate = date.startOf('year');
    let endDate = date.endOf('year');
    if(!to_date) return Interval.fromDateTimes(startDate, endDate);

    return Interval.fromDateTimes(startDate, DateTime.now());
  }

  getQuarterInterval(date = DateTime.now(), to_date = false) {
    const startDate = date.startOf('quarter');
    const endDate = date.endOf('quarter');
    if(!to_date) return Interval.fromDateTimes(startDate, endDate);

    return Interval.fromDateTimes(startDate, DateTime.now());
  }

  getMonthInterval(date = DateTime.now(), to_date = false) {
    const startDate = date.startOf('month');
    const endDate = date.endOf('month');
    if(!to_date) return Interval.fromDateTimes(startDate, endDate);

    return Interval.fromDateTimes(startDate, DateTime.now());
  }

  humanizeInterval(interval) {
    return `${interval.start.toLocaleString()} - ${interval.end.toLocaleString()}`
  }

  intervalsMap = {
    custom: {
      call_to_method: [], 
      name: 'Custom'
    },
    all_time: { 
      call_to_method: ['getAllTimeInterval', DateTime.now()],
      name: 'All Time'
    },
    last_year: {
      call_to_method: ['getYearInterval', DateTime.now().minus({ years: 1 })],
      name: 'Last year'
    },
    current_year: {
      call_to_method: ['getYearInterval', DateTime.now(), true],
      name: 'Current year'
    },
    current_quarter: {
      call_to_method: ['getQuarterInterval', DateTime.now(), true],
      name: 'Current Quarter'
    },
    current_month_to_date: {
      call_to_method: ['getMonthInterval', DateTime.now(), true],
      name: 'Current Month'
    },
    last_month: {
      call_to_method: ['getMonthInterval', DateTime.now().minus({ months: 1 })],
      name: 'Last Month'
    }
  };
}
