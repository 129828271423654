import { Controller } from "stimulus"
import FlashUtils from '../packs/flash_utils'
export default class extends Controller {
  static targets = [ "source" ]
  flash = new FlashUtils('Copied!', 'success')

  copy() {
    var range = document.createRange()
    range.selectNode(this.sourceTarget)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand("copy");
    window.getSelection().removeAllRanges()
    this.sourceTarget.setAttribute('title', 'Copied!')
    this.flash.display()
  }

  // This allows copyng from inputs without line breaks
  copyInput() {
    /* Select the text field */
    this.sourceTarget.select();
    this.sourceTarget.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.sourceTarget.setAttribute('title', 'Copied!')
    this.flash.display()
  }
}
