import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static values = {
    prefix: String
  }

  connect() {}

  removeTag(event) {
    const element = event.currentTarget.parentElement
    if(element.dataset.persisted == 'true') {
      let id = element.dataset.id
      console.log(this.prefixValue + `_${id}__destroy`)
      let el = document.getElementById(this.prefixValue + `_${id}__destroy`)
      el.value = 'true'
      element.style = 'display: none'
    }
    else { 
      element.remove()
    }
  }
}
