import { Controller } from "stimulus"
import { observeDOM } from '../../javascript/packs/arpu_utils';

export default class extends Controller {
  static values = {
    active: String,
    target: String,
    template: String,
    observeAttribute: String
  }

  connect() {
    if (this.activeValue) {
      const templateId = [this.activeValue, this.templateValue].join('_')
      this.showTemplate(templateId)
    }
    if(this.observeAttributeValue) {
      observeDOM({ attributes: true }, this.element, this.selectedValueCallback);
    }
  }

  selectedValueCallback = (changes) => {
    changes.forEach((change) => {
      if(change.attributeName == this.observeAttributeValue) {
        this.activeValue = change.target.getAttribute(this.observeAttributeValue);
        this.dispatchChangeEvent()
      }
    })
  }

  activeValueChanged(value) { 
    const templateId = [value, this.templateValue].join('_')
    this.showTemplate(templateId)
  }

  showTemplate(templateId) {
    const container = document.getElementById(this.targetValue)
    const template = document.getElementById(templateId)
    const clone = template.content.cloneNode(true)
    container.replaceChildren(clone)
  }

  dispatchChangeEvent() {
    if ('createEvent' in document) {
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', false, true);
      this.element.dispatchEvent(evt);
    }
    else
      this.element.fireEvent('onchange');
  }
}
