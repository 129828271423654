import { Controller } from "stimulus"
import { arpu_currency_formatting } from "../packs/arpu_utils.js"

export default class extends Controller {
  static targets = [ "image", "price", "comparedAtPrice", "item" ]
  static values = { 
    discount: String,
    moneyFormattingJsNumberFormat: String,
    moneyFormattingPrefix: String,
    moneyFormattingSuffix: String,
    currency: String
  }

  connect() {
    this.element[this.identifier] = this;
  }

  optionSelected(event) {
    const element = event.currentTarget
    const option = element.options[element.selectedIndex]
    let selected_item = JSON.parse(option.dataset.object)
    this.imageTarget.src = selected_item.featured_image_src
    this.priceTarget.innerHTML = arpu_currency_formatting(
                                   selected_item.sale_price, 
                                   this.moneyFormattingPrefixValue, 
                                   this.moneyFormattingJsNumberFormatValue,
                                   this.moneyFormattingSuffixValue,
                                   this.currencyValue
                                 )
    if(this.hasComparedAtPriceTarget) {
      this.comparedAtPriceTarget.innerHTML = this.moneyFormat(selected_item.sale_price, selected_item.price, selected_item.compared_at_price)
    }

   this.itemTarget.dataset.item = option.dataset.object

  }

  moneyFormat(sale,price,compared_at_price) {
    let nsale = Number(sale)
    let nprice = Number(price)
    let format = this.moneyFormattingJsNumberFormatValue
    let prefix = this.moneyFormattingPrefixValue
    let suffix = this.moneyFormattingSuffixValue
    let currency_code = this.currencyValue

    let ncompared_at_price = Number(compared_at_price)

    let l_nsale = arpu_currency_formatting(nsale, prefix, format, suffix, currency_code)
    let l_nprice = arpu_currency_formatting(nsale, prefix, format, suffix, currency_code)
    let l_ncompared_at_price = arpu_currency_formatting(nsale, prefix, format, suffix, currency_code)

    if ((nsale < nprice) && (l_nsale != l_nprice)){
      return l_nprice
    }
    else {
      if ((nprice < ncompared_at_price) && (l_nprice != l_ncompared_at_price)) {
        return l_ncompared_at_price
      }
      else return ""
    }
  }

  
}

