class FlashUtils {
  constructor(content, type, timer) {
    this.content = content;
    this.type = type;
    this.timer = timer ? timer : 1000 // Default 
  }

  display() {
    document
      .querySelector("#notification-box")
      .append(this.buildNotification());
  }

  buildNotification() {
    const notification = document.createElement("div");
    notification.className = `notification-bottom-right floating notification is-${
      this.type
    }`;
    notification.dataset.controller = "flash";
    notification.dataset.flashTimerValue = this.timer;
    notification.innerHTML = `
      <a data-action="flash#close" class="delete"><i class="fas fa-times-circle"></i></a>
      ${this.content}
    `

    return notification;
  }

  buildStaticNotification() {
    this.destroyStaticNotification()
    const errorNotificationEl = document.createElement('div') 
    errorNotificationEl.classList.add('notification-box')

    const errorEl = document.createElement('div')
    errorEl.classList.add('notification', `notification--${this.type}`)
    errorEl.innerHTML = this.content
    errorNotificationEl.append(errorEl)

    return errorNotificationEl;
  }

  destroyStaticNotification() {
    const staticNotifications = document.getElementsByClassName('notification-box')
    if(staticNotifications)
      Array.from(staticNotifications).forEach((el) => {
        el.remove()
      })
  }
}

export default FlashUtils;