import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newStoreModal" ]
  openModal() {
    this.newStoreModalTarget.style.display = "block"
  }
  closeModal() {
    this.newStoreModalTarget.style.display = "none"
  }
}
