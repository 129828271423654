import { Controller as BaseController } from "stimulus";

export class Controller extends BaseController {
  static targets = [ 'source', 'container', 'template' ]
  static values = { prefix: String }
  
  connect() {}

  inputTag(event) {
     const input = event.currentTarget
     let tag_value = input.value
     if(tag_value[tag_value.length-1]==',') {
        this.buildTag(tag_value.slice(0,-1).trim())
        input.value = ""
     }
  }

  finishInputTag(event) {
    const input = event.currentTarget
    let tag_value = input.value
    if(tag_value.length >= 1) {
      this.buildTag(tag_value.trim())
      input.value = ""
    }
  }

  buildTag(tag) {
    let template_id = new Date().valueOf()
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, template_id)
    content = content.replace(/TAG/g, tag)
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }
}
