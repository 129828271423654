import { Controller } from 'stimulus'
import { DateTime } from 'luxon';
export default class extends Controller {
  static values = {
    data: Array
  }

  connect(){
    this.updateData()
  }

  updateData(){
    this.updateDataTable(this.dataValue)
  }

  updateDataTable(data){
    const table = document.getElementById('campaign_version_table')
    const template = document.getElementById('campaign_version_row')
    if(!table || !template) return

    const tbody = table.querySelector('tbody')

    this.clearTable(tbody)

    data.forEach((row,i) => { 
      const clone = template.content.cloneNode(true)
      let td = clone.querySelectorAll('td')
      Array.from(td).slice(0, 2).forEach((el) => el.classList.add('text-center'))
      Array.from(td).slice(2).forEach((el) => el.classList.add('text-right'))
      td[0].textContent = row.start_date
      td[1].textContent = row.revenue
      td[2].textContent = row.emails
      td[3].textContent = row.revenue_email
      td[4].textContent = row.open_rate
      td[5].textContent = row.click_rate
      td[6].textContent = row.convertion_rate
      if(row.email_preview_url) {
        let previewLink = document.createElement('a')
        previewLink.target = '_blank'
        previewLink.href = row.email_preview_url
        previewLink.textContent = 'Preview'
        td[7].appendChild(previewLink)
      }
      tbody.appendChild(clone)
    })
  }

  changePeriodOptions(event) {
    if (event.currentTarget.getAttribute('data-content-select-active-value') == 'chart_version_performance') {
      this.oneYearPeriodOptions()  
    } else {
      this.allPeriodOptions()  
    }
  }

  oneYearPeriodOptions() {
    const optionsSelector = '#period-filter div.c-form--select-box-content ul'
    const datepickerSelector = '#period-filter > div.c-period-filter-inline-inputs-section > div:nth-child(2) > div > div > div > div.flatpickr-input'
    const oneYearAgo = DateTime.now().minus({ years: 1, days: 1}).toISODate()
    const periodOptions = Array.from(document.querySelector(optionsSelector).children) 
    periodOptions.forEach((el) => {
      if(el.dataset.id == 'last_month') el.click()
      if(el.dataset.id == 'all_time') {
        el.style.display = 'none'
      }
    })
    const datepicker = document.querySelector(datepickerSelector)
    datepicker._flatpickr.set('minDate', oneYearAgo)
  }

  allPeriodOptions() {
    const optionsSelector = '#period-filter div.c-form--select-box-content ul'
    const datepickerSelector = '#period-filter > div.c-period-filter-inline-inputs-section > div:nth-child(2) > div > div > div > div.flatpickr-input'
    Array.from(document.querySelector(optionsSelector).children).forEach((el) => {
      if(el.dataset.id == 'all_time') {
        el.removeAttribute('style')
      }
    })
    document.querySelector(datepickerSelector)._flatpickr.set('minDate', null)
  }

  clearTable(node) {
    var child = node.lastElementChild 
    while (child) {
      node.removeChild(child)
      child = node.lastElementChild
    }
  }
}
