import { Controller } from "stimulus"
import { debounce } from 'lodash';
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = [ "results", "feedback" ]
  static values = { url: String, datasetResult: String, waitTimeMs: Number, charThreshold: Number }
  
  connect() {
    this.element[this.identifier] = this
    let waitFor = (this.waitTimeMsValue > 0) ? this.waitTimeMsValue : 1500
    this.debounceUpdate = debounce(this.update, waitFor)
  }

  update(event) {
    if (this.charThresholdValue > 0 && Array.from(event.target.value.trim()).length < this.charThresholdValue)
      return

    const data = new FormData()
    const self = this

    self.toggleSearchingClass()
    data.append("q", event.target.value)
    Rails.ajax({
      url: this.urlValue,
      type: 'POST',
      data: data,
      success: function(response) {
        self.resultsTarget.insertAdjacentHTML('beforeEnd', response.body.innerHTML)
      },
      complete: () => {
        self.toggleSearchingClass(false)
      }
    })
  }

  toggleSearchingClass(enable=true) {
    this.hasFeedbackTarget && this.feedbackTarget.classList.toggle('searching', enable)
  }
}
