import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'saveButton' ]

  formIsChanged(event) {
    this.setChanged("true")
  }
  
  setChanged(changed) {
    this.data.set("changed", changed)
    this.saveButtonTarget.classList.remove('filter--notMatch')
  }

}
