import { arpu_currency_formatting, arpu_number_from_currency_formatting } from "../arpu_utils.js"

export default class Cashier {
  lineItems;
  currencyFormatter;
  quantityAttrName;

  constructor(items = [], currencyFormatter = null, quantityAttrName = 'qty', priceAttrName = 'sale_price') {
    this.currencyFormatter = currencyFormatter;
    this.quantityAttrName = quantityAttrName;
    this.priceAttrName = priceAttrName;
    this.lineItems = items;
  }

  clear() {
    this.lineItems = new Array();
  }

  include(item, quantity=0) {
    let _item = (this.lineItems.push(item), item);
    _item[this.quantityAttrName] = quantity;
    this.lineItems;
  }
  
  scan(item, quantity) {
    return this.add(item, quantity);
  }

  applyDiscount(item, discount, discount_type) {
    let price = item.price
    let sale_price = item.sale_price

    /* we don't apply discount if item already have 
       a discount applyed. */
    if((discount <= 0) || (sale_price < price)) {
      return this.lineItems;
    }

    if(discount_type == 'percentage') {
      sale_price = price * (1 - discount / 100.0);
    }
    else {
      sale_price = price - discount;
    }
    return this.modifyLineItemPrice(item, sale_price);
  }

  add(item, quantity) {
    let _item = this.findLineItem(item);
    if (!_item) return false; 

    if (this.isFull(_item, quantity)) {
      _item[this.quantityAttrName] = this.limit;
    } else {
      _item[this.quantityAttrName] += quantity;
    }

    return this.lineItems;
  }

  reduce(item, quantity) {
    let _item = this.findLineItem(item);
    if (!_item) return;

    _item[this.quantityAttrName] -= quantity;
    if(_item[this.quantityAttrName] == 0) this.remove(_item);

    return this.lineItems;
  }

  remove(item) {
    const index = this.lineItems.map((i)=>i.id).indexOf(item.id);
    if (index > -1) {
      this.lineItems.splice(index, 1);
    }

    return this.lineItems;
  } 

  modifyLineItemPrice(item, price) {
    let _item = this.findLineItem(item);
    if(_item) {
      _item[this.priceAttrName] = price;
    }

    return this.lineItems;
  }

  modifyLineItemQuantity(item, quantity) {
    let _item = this.findLineItem(item);
    if(quantity == 0) return this.remove(_item);

    if(_item) {
      _item[this.quantityAttrName] = quantity;
    }

    return this.lineItems;
  }

  // if limited item this will be true when reach it's limit
  isFull(item, qty) {
    if (!this.isFree(item)) return false;

    return (item[this.quantityAttrName] + qty) > this.limit;
  }

  // Total quantity of a item
  itemQuantity(item) {
    let _item = this.findLineItem(item);
    if(!_item) return item[this.quantityAttrName];

    return _item[this.quantityAttrName];
  }

  findLineItem(item) {
    return this.findLineItemById(item.id);
  }

  findLineItemById(id) {
    return this.lineItems.find((i) => id == i.id);
  }

  isFree(item) {
    const price = this.currencyFormatter.number_from_currency(Number(item.sale_price));
    return price <= 0;
  } 

  get subtotal() {
    const subtotalFn = (subtotal, item) => {
      return subtotal += (this.currencyFormatter.number_from_currency(item.sale_price) * item[this.quantityAttrName]);
    };

    return this.lineItems.reduce(subtotalFn, 0);
  }

  get subtotalFormatted() {
    return this.currencyFormatter.format(this.subtotal);
  }

  get lineItemsCount() {
    const countFn = (count, item) => count += item[this.quantityAttrName];
    return this.lineItems.reduce(countFn, 0);
  }

  set limit(qty) {
    this._limit = qty;
  }

  get limit() {
    return this.limit = this._limit || 1;
  }
}

