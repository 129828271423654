import { Controller } from "stimulus"
import { isValidDate } from "../packs/arpu_utils"
import { Strftime_Locales } from '../packs/locales'
import Bugsnag from '@bugsnag/js'

export default class extends Controller {
  static values = { delayed_date: String , scheduled_at: String }
  static targets = ["selection", "calendar", "confirm", "confirmCalendar", "success", "error", "nextChargeDate", "calendarDate", "manageSubscriptionError", "confirmShipNow", "successShipNow", "manageSubscriptionError", "confirmSkipOrder", "successSkipOrder"]
 
  connect() {
    import("strftime").then(strftime => {
      this.strftime = strftime.default
      this.setOptionFromParams()
    })
    this.checkConfirmButtons()
  }
  
  delayedDateValueChanged() {
    this.checkConfirmButtons()
    this.setNextChargeDate()
  }

  setOptionFromParams() {
   const next_charge_day = this.data.get("next-charge-day")
   const calendar = this.data.get("calendar")
   const ship_now = this.data.get("ship-now")
   const skip_order = this.data.get("skip-order")
   if(calendar) {
     this.selectionTarget.classList.add('filter--notMatch')
     this.confirmShipNowTarget.classList.add('filter--notMatch')
     this.calendarTarget.classList.remove('filter--notMatch')
   }
   else if(ship_now) {
     this.selectionTarget.classList.add('filter--notMatch')
     this.calendarTarget.classList.add('filter--notMatch')
     this.confirmShipNowTarget.classList.remove('filter--notMatch')
   }
   else if(skip_order) {
     this.selectionTarget.classList.add('filter--notMatch')
     this.calendarTarget.classList.add('filter--notMatch')
     this.confirmSkipOrderTarget.classList.remove('filter--notMatch')
   }
   else {
    if(next_charge_day) this.setOptionFromSelectedDate(next_charge_day)
   }
  }
  
  selectOption(event) {
    const element = event.currentTarget
    const next_charge_day = element.getAttribute("data-next-charge-day")
    this.setOptionFromSelectedDate(next_charge_day)
  }

  selectShipNow(event) {
    this.confirmShipNow()
  }

  selectSkipOrder(event) {
    this.confirmSkipOrder()
  }

  setOptionFromSelectedDate(next_charge_day) {
    this.delayedDateValue = next_charge_day
    this.selectionTarget.classList.add('filter--notMatch')
    this.confirmTarget.classList.remove('filter--notMatch')
  }

  applyDate(date_text){
    let date_array = [null].concat(date_text.split('-'))
    date_array[2] = date_array[2] - 1 // month zero_index 
    return new (Function.prototype.bind.apply(Date, date_array))
  }

  setDateFromCalendar() {
    const calendar_date = this.calendarDateTarget.getAttribute("data-flatpickr-date-value")
    this.delayedDateValue = this.strftime('%Y-%m-%d', new Date(calendar_date))
  }

  confirmCalendarDate(event) {
    this.setDateFromCalendar()
    this.calendarTarget.classList.add('filter--notMatch')
    this.confirmTarget.classList.remove('filter--notMatch')
  }

  confirmShipNow() {
    this.selectionTarget.classList.add('filter--notMatch')
    this.confirmShipNowTarget.classList.remove('filter--notMatch')
  }

  confirmSkipOrder() {
    this.selectionTarget.classList.add('filter--notMatch')
    this.confirmSkipOrderTarget.classList.remove('filter--notMatch')
  }

  setNextChargeDate() {
    const date = this.applyDate(this.delayedDateValue)
    this.nextChargeDateTargets.forEach((element) => {
      if(isValidDate(date)) {
        const format = element.dataset.format 
        const locale = element.dataset.locale
        element.innerHTML = this.strftimeLocalized(format, date, locale)
      } else {
        element.innerHTML = ''
      }
    })
  }

  strftimeLocalized(format, date, locale) {
    let strftime = this.strftime.localize(Strftime_Locales(locale))
    return strftime(format, date)
  }

  selectCalendar(event) {
    const element = event.currentTarget
    this.selectionTarget.classList.add('filter--notMatch')
    this.calendarTarget.classList.remove('filter--notMatch')
  }

  cancel(event) {
    this.selectionTarget.classList.remove('filter--notMatch')
    this.calendarTarget.classList.add('filter--notMatch')
    this.confirmTarget.classList.add('filter--notMatch')
    this.successTarget.classList.add('filter--notMatch')
    this.confirmShipNowTarget.classList.add('filter--notMatch')
    this.confirmSkipOrderTarget.classList.add('filter--notMatch')
    this.errorTarget.classList.add('filter--notMatch')
    if (this.hasManageSubscriptionErrorTarget) {
      this.manageSubscriptionErrorTarget.classList.add('filter--notMatch') 
    }
    this.delayedDateValue = ""
  }

  shipNow(event){
    let target = event.currentTarget 
    target.setAttribute('disabled', true)
    let url = this.data.get('shipnow-url')
    if(url==="") {
      target.removeAttribute('disabled')
      return
    } else {
 
      fetch(url, {
        method: 'PATCH'
      })
      .then(response => {
        if(!response.ok) return response.text().then(text => { throw text })
        else {
          this.confirmShipNowTarget.classList.add('filter--notMatch')
          this.successShipNowTarget.classList.remove('filter--notMatch') 
        }
      })
      .catch(error => this.handleError(error))
      .finally(() => {
        target.removeAttribute('disabled')
      })
    }
  }

  skipOrder(event){
    let target = event.currentTarget 
    target.setAttribute('disabled', true)
    let url = this.data.get('skiporder-url')
    if(url==="") {
      target.removeAttribute('disabled')
      return
    } else {
 
      fetch(url, {
        method: 'PATCH'
      })
      .then(response => {
        if(!response.ok) return response.text().then(text => { throw text })
        else {
          this.confirmSkipOrderTarget.classList.add('filter--notMatch')
          this.successSkipOrderTarget.classList.remove('filter--notMatch') 
        }
      })
      .catch(error => this.handleError(error))
      .finally(() => {
        target.removeAttribute('disabled')
      })
    }
  }

  submit(event){
    let formData = new FormData()
    let target = event.currentTarget
    let delayed_date = new Date(this.delayedDateValue)
    let date
    if (isValidDate(delayed_date)) {
      date = this.delayedDateValue
    } else {
      return this.cancel()
    }
    formData.append("delayed_date", date)
    target.setAttribute('disabled', true)
    let url = this.data.get('update-url')
    if(url==="") {
      target.removeAttribute('disabled')
      return
    } else {
      fetch(url, {
        method: 'PUT',
        body: formData
      })
      .then(response => {
        if(!response.ok) return response.text().then(text => { throw text })
        else {
          this.confirmTarget.classList.add('filter--notMatch')
          this.successTarget.classList.remove('filter--notMatch') 
        }
      })
      .catch(error => this.handleError(error))
      .finally(() => {
        target.removeAttribute('disabled')
      })
    }
  }

  checkConfirmButtons() {
    if(this.delayedDateValue === "") {
      this.confirmCalendarTarget.setAttribute('disabled', true)
    } else {
      this.confirmCalendarTarget.removeAttribute('disabled')
    }
  }

  // [Deprecated] Redirecting to error page instead of rendering error
  showErrorPage(error) {
    switch (error.err_type) {
      case 'expiration_time':
        window.location.href = error.redirect_page_url || this.defaultErrorUrl
        break
      case 'not_found':
        this.manageSubscriptionErrorTarget.classList.remove('filter--notMatch')
        break
      default:
        this.errorTarget.classList.remove('filter--notMatch')
    }
    this.confirmTarget.classList.add('filter--notMatch')
    this.confirmShipNowTarget.classList.add('filter--notMatch')
    this.confirmSkipOrderTarget.classList.add('filter--notMatch')
  }

  handleError(error) {
    if(error) {
      let jsonError = JSON.parse(error)
      if(jsonError.message) Bugsnag.notify(jsonError.message)
      window.location.href = jsonError.redirect_page_url || this.defaultErrorUrl
    } else {
      window.location.href = this.defaultErrorUrl
    }
  }

  get defaultErrorUrl() {
    let url = window.location.href
    url = url.split('/')
    url.pop()
    url = url.concat("error")
    return url.join("/")
  }
}
